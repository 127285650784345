<template>
	<section>
		<b-modal 
			v-model="isCardModalActive"
			width="auto"
			scroll="keep"
			has-modal-card
			trap-focus
		>
			<form ref="form">
				<div class="modal-card" style="width: auto">
					<header class="modal-card-head">
						<p class="title is-size-5-desktop is-size-6-mobile">{{ formTitle }}</p>
					</header>
					<section class="modal-card-body">
						<b-field label="Nama Perusahaan*">
							<b-input
								v-model="active_work_experience.company"
								placeholder="Nama Perusahaan tidak boleh hanya angka"
								validation-message="Silahkan isi bagian ini"
								@keypress.native="onlyAlphaNum"
								maxlength="100"
								required
							></b-input>
						</b-field>
						<div class="columns is-desktop">
							<div class="column">
								<b-field label="Jenis Industri*">
									<b-input
										v-model="active_work_experience.industrial_type"
										placeholder="Contoh: Retail, Manufaktur, Jasa"
										validation-message="Silahkan isi bagian ini"
										@keypress.native="onlyAlphaNum"
										maxlength="100"
										required
									></b-input>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Jenis Pekerjaan*">
									<b-select  
										v-model="active_work_experience.work_type_id"
										@input="updateWorkTypeName"
										placeholder="Jenis Pekerjaan" 
										expanded
									>
										<option 
											v-for="work in work_type"
											:key="work.id"
											:value="work.id"
										>{{ work.name }}</option>
									</b-select>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Kota Penempatan*">
									<b-input
										v-model="active_work_experience.work_city"
										placeholder="Kota Penempatan"
										validation-message="Silahkan isi bagian ini"
										@keypress.native="onlyAlphabet"
										maxlength="50"
										required
									></b-input>
								</b-field>
							</div>
						</div>
						<div class="columns is-desktop">
							<div class="column">
								<b-field label="Tanggal Mulai Bekerja*">
									<b-datepicker
										placeholder="Klik untuk memilih tanggal"
										v-model="active_work_experience.temp_start_date"
										@input="saveStartDate"
										:min-date="minDate"
										:mobile-native="false"
										trap-focus
									></b-datepicker>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Jabatan Terakhir*">
									<b-input
										v-model="active_work_experience.last_position"
										placeholder="Jabatan Terakhir"
										validation-message="Silahkan isi bagian ini"
										@keypress.native="onlyAlphaNum"
										maxlength="100"
										required
									></b-input>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Gaji*" :message="convertToRupiah">
									<b-input
										v-model="active_work_experience.salary"
										@keypress.native="onlyNumber"
										placeholder="Gaji"
										validation-message="Silahkan isi bagian ini"
										required
									></b-input>
								</b-field>
							</div>
						</div>
						<p class="subtitle is-6 has-text-weight-bold">
							Jika sudah berhenti bekerja, silahkan isi data berikut
						</p>
						<div class="columns is-desktop">
							<div class="column">
								<b-field label="Tanggal Berhenti Bekerja">
									<b-datepicker
										placeholder="Klik untuk memilih tanggal"
										v-model="active_work_experience.temp_end_date"
										@input="saveEndDate"
										:min-date="minDateEndWork"
										:mobile-native="false"
										trap-focus
									></b-datepicker>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Alasan Berhenti Bekerja">
									<b-select  
										v-model="active_work_experience.reason_id"
										@input="updateReasonName"
										placeholder="Alasan Berhenti Bekerja"
										validation-message="Silahkan isi bagian ini" 
										expanded
									>
										<option 
											v-for="item in reason"
											:key="item.id"
											:value="item.id"
										>{{ item.name }}</option>
									</b-select>
								</b-field>
							</div>
						</div>
					</section>
					<footer class="modal-card-foot is-pulled-right">
						<b-button 
							class="is-pulled-right"  
							@click="closeModal()"
						>Kembali</b-button>
						<b-button 
							class="is-pulled-right"
							type="is-info" 
							@click.prevent="saveWorkExperience"
						>Simpan</b-button>
					</footer>
				</div>
			</form>
		</b-modal>
		<b-field>
			<b-button
				class="is-text"
				icon-left="chevron-left"
				@click="prev()"
			>
				Sebelumnya
			</b-button>
		</b-field>
		<div class="columns">
			<div class="column">
				<b-field>
					<h4 class="title is-size-5-desktop is-size-6-mobile"> 
						12 Pengalaman Kerja
					</h4>
				</b-field>
			</div>
		</div>
		<div class="columns is-desktop">
			<div class="column is-one-quarter is-offset-9">
				<b-field>
					<b-button  
						class="button is-success is-pulled-right"
						:loading="isLoading" 
						@click="add()"
						expanded 
					>
						Tambah Data
					</b-button>
				</b-field>
			</div>
		</div>
		<b-field>
			<b-checkbox
				class="mt-4" 
				type="is-info"
				v-model="checkbox"
			>
				Tidak memiliki riwayat pengalaman kerja
			</b-checkbox>
		</b-field>
		<b-table 
			class="box"
			:data="work_experiences.length == 0 ? empty : work_experiences"
			:loading="isLoading"
		>
		<b-table-column
				label="Data Pengalaman Kerja"
				:visible="work_experiences.length == 0"
				v-slot="props" 
			>
				{{ props.row }}
			</b-table-column>
			<b-table-column
				label="Nama Perusahaan"
				:visible="work_experiences.length != 0"
				v-slot="props" 
			>
				{{  
					props.row.company + " (" + 
					props.row.last_position + ")" 
				}}
			</b-table-column>
			<b-table-column
				:visible="work_experiences.length != 0"
				field="action"
				label="Aksi"
				v-slot="props" 
				centered
			>
				<b-button  
					class="button is-warning is-light" 
					@click="editWorkExperience(props.row)"
				>Ubah</b-button>
			</b-table-column>
		</b-table>
		<b-field>
			<b-button  
				class="button is-info" 
				@click="next" 
				:loading="isLoading"
				expanded
			>
				Simpan & Lanjut
			</b-button>
		</b-field>
	</section>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import { dateFormat } from '@/plugins/timeformat.js'
	export default {
		data() {
			const today = new Date()
			return {
				isCardModalActive: false,
				isLoading: false,
				checkbox: false,
				
				work_type: [],
				reason: [],

				empty: ['Belum ada data'],

				edited_index: -1,
				work_experiences: [],
        active_work_experience: {
          id: null,
          company: null,
          start_work: null,
          work_city: null,
          end_work: null,
          last_position: null,
          salary: null,
          industrial_type: null,
          job_description: null,
          applicant_id: null,
          work_type_id: null,
          work_type: {
            name: null
          },
          reason_id: null,
          reason: {
            name: null
          },
          status: false
        },
        active_work_experience_default: {
          id: null,
          company: null,
          start_work: null,
          work_city: null,
          end_work: null,
          last_position: null,
          salary: null,
          industrial_type: null,
          job_description: null,
          applicant_id: null,
          work_type_id: null,
          work_type: {
            name: null
          },
          reason_id: null,
          reason: {
            name: null
          },
          status: false
				},
				minDate: new Date(today.getFullYear() - 70, today.getMonth(), today.getDate()),
				minDateEndWork: today,
			}
		},
		mounted() {
			this.refresh()
		},
		computed: {
			...mapGetters({
        applicant: "applicant",
        stepper_position: "stepper_position"
      }),
      formTitle() {
				return this.edited_index === -1 
					? 'Tambah Data Pengalaman Kerja' 
					: 'Ubah Data Pengalaman Kerja'
      },
			convertToRupiah() {
      var output = "";
      if (this.active_work_experience.salary) {
        var value = this.active_work_experience.salary;
        var rupiah = "";
        var final_value = value
          .toString()
          .split("")
          .reverse()
          .join("");
        for (var i = 0; i < final_value.length; i++)
          if (i % 3 == 0) rupiah += final_value.substr(i, 3) + ".";

        output =
          "Rp. " +
          rupiah
            .split("", rupiah.length - 1)
            .reverse()
            .join("");
      }
      return output;
    },
		},
		methods: {
			...mapActions({
        setApplicant: "setApplicant",
        setStepperPosition: "setStepperPosition"
      }),
      add() {
        this.isCardModalActive = true
        this.active_work_experience = Object.assign({}, this.active_work_experience_default) 
        this.edited_index = -1
        this.isLoading = false
		this.minDateEndWork = new Date()
      },
      next() {
				if (this.work_experiences.length === 0 && this.checkbox === false) {
					this.$buefy.snackbar.open({
						message: 'Riwayat pengalaman kerja masih kosong, silahkan mengisi terlebih dahulu',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else if (this.work_experiences.length > 0 && this.checkbox === true) {
						this.$buefy.snackbar.open({
						message: 'Silahkan memeriksa kembali riwayat pengalaman kerja',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					}) 
				}
        else {
					this.setStepperPosition(12)
				}
      },
      async refresh() {
        this.isLoading = true
        await this.$axios.get('applicants/' + this.applicant.id + `/work-experiences`)
        .then(response => {
          this.work_experiences = response.data.data
        })
        .catch(e => {
          this.message = "Error: " + e.response.data.message
          this.$buefy.snackbar.open({
						message: this.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
        })
        .finally(() => {
          this.isLoading = false
        })
        if (!this.work_type.length) {
          this.isLoading = true
          await this.$axios.get('work-types')
          .then(response => {
						this.work_type = response.data.data
          })
          .catch(e => {
            this.message = "Error: " + e.response.data.message
            this.$buefy.snackbar.open({
							message: this.message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
          })
          .finally(() => {
            this.isLoading = false
          })
        }
        if (!this.reason.length) {
          await this.$axios.get('reasons')
          .then(response => {
            this.reason = response.data.data
          })
          .catch(e => {
            this.message = "Error: " + e.response.data.message
            this.$buefy.snackbar.open({
							message: this.message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
          })
          .finally(() => {
            this.isLoading = false
          })
        }
      },
      updateWorkTypeName() {
        for (var i = 0; i < this.work_type.length; i++)  {
          if (this.work_type[i].id === this.active_work_experience.relation_type_id) {
            this.active_work_experience.relation_type.name = this.work_type[i].name
            break
          }
        }
      },
      updateReasonName() {
        for (var i = 0; i < this.reason.length; i++)  {
          if (this.reason[i].id === this.active_work_experience.reason_id) {
            this.active_work_experience.reason.name = this.reason[i].name
            break
          }
        }
      },
      saveStartDate (date) {
        this.active_work_experience.start_work = dateFormat(date)
		this.minDateEndWork = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
      },
      saveEndDate (date) {
        this.active_work_experience.end_work = dateFormat(date)
      },
      closeModal() {
        this.isCardModalActive = false
        this.active_work_experience = Object.assign({}, this.active_work_experience_default) 
        this.edited_index = -1
        this.isLoading = false
      },
      async saveWorkExperience(e) {
		// delete leading spaces
		this.active_work_experience.company = this.active_work_experience.company ? this.active_work_experience.company.replace(/^\s*/, "") : null
		this.active_work_experience.company = this.active_work_experience.company != null ? this.active_work_experience.company.replace(/^[0-9\s]+$/, "") : null
		this.active_work_experience.company = this.active_work_experience.company != null ? this.active_work_experience.company.length == 1 && (this.active_work_experience.company == 0 || this.active_work_experience.company == "0") ? "" : this.active_work_experience.company : null
this.active_work_experience.company = this.active_work_experience.company != null ? this.active_work_experience.company.length > 1 && this.active_work_experience.company.trim().length === 1 && (this.active_work_experience.company.charAt(0) == 0 || this.active_work_experience.company.charAt(0) == "0") ? "" : this.active_work_experience.company : null
		this.active_work_experience.industrial_type = this.active_work_experience.industrial_type ? this.active_work_experience.industrial_type.replace(/^\s*/, "") : null
		this.active_work_experience.industrial_type = this.active_work_experience.industrial_type != null ? this.active_work_experience.industrial_type.length == 1 && (this.active_work_experience.industrial_type == 0 || this.active_work_experience.industrial_type == "0") ? "" : this.active_work_experience.industrial_type : null
this.active_work_experience.industrial_type = this.active_work_experience.industrial_type != null ? this.active_work_experience.industrial_type.length > 1 && this.active_work_experience.industrial_type.trim().length === 1 && (this.active_work_experience.industrial_type.charAt(0) == 0 || this.active_work_experience.industrial_type.charAt(0) == "0") ? "" : this.active_work_experience.industrial_type : null
		this.active_work_experience.work_city = this.active_work_experience.work_city ? this.active_work_experience.work_city.replace(/^\s*/, "") : null
		this.active_work_experience.last_position = this.active_work_experience.last_position ? this.active_work_experience.last_position.replace(/^\s*/, "") : null
		this.active_work_experience.last_position = this.active_work_experience.last_position != null ? this.active_work_experience.last_position.length == 1 && (this.active_work_experience.last_position == 0 || this.active_work_experience.last_position == "0") ? "" : this.active_work_experience.last_position : null
this.active_work_experience.last_position = this.active_work_experience.last_position != null ? this.active_work_experience.last_position.length > 1 && this.active_work_experience.last_position.trim().length === 1 && (this.active_work_experience.last_position.charAt(0) == 0 || this.active_work_experience.last_position.charAt(0) == "0") ? "" : this.active_work_experience.last_position : null

				if (this.$refs.form.checkValidity() 
					&& this.active_work_experience.start_work != null) {
						if (!(/^[A-Za-z0-9\s]+$/i.test(this.active_work_experience.company)) || !(/^[A-Za-z0-9\s]+$/i.test(this.active_work_experience.industrial_type))) {
							this.$buefy.snackbar.open({
								message: "Gagal! Nama perusahaan dan jenis industri hanya bisa alfabet, angka, dan spasi",
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
						return false;
						}
						if (!(/^[A-Za-z\s]+$/i.test(this.active_work_experience.work_city))) {
							this.$buefy.snackbar.open({
								message: "Gagal! Kota hanya bisa alfabet dan spasi",
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
						return false;
						}
						if (!(/^[A-Za-z0-9\s]+$/i.test(this.active_work_experience.last_position))) {
							this.$buefy.snackbar.open({
								message: "Gagal! Jabatan terakhir hanya bisa alfabet, angka, dan spasi",
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
						return false;
						}
						if (!(/^[0-9]+$/i.test(this.active_work_experience.salary))) {
							this.$buefy.snackbar.open({
								message: "Gagal! Gaji hanya bisa angka",
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
						return false;
						}
						if (this.active_work_experience.end_work && this.active_work_experience.start_work >= this.active_work_experience.end_work) {
							this.$buefy.snackbar.open({
								message: "Gagal! Tanggal mulai kerja harus lebih kecil dari tanggal berhenti kerja",
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
						return false;
						}
          this.isLoading = true
          this.message = null
          if (this.edited_index > -1) {
            await this.$axios.patch(`applicants/` + this.applicant.id + `/work-experiences/` + this.active_work_experience.id, this.active_work_experience
            )
            .then(response => {
              this.message = response.data.message
              if (response.data.status) {
								Object.assign(this.work_experiences[this.edited_index], this.active_work_experience)
								this.refresh()
                this.closeModal()
              }
            })
            .catch(e => {
              this.message = "Error: " + e.response.data.message
            })
            .finally(() => {
				this.minDateEndWork = new Date()
              this.isLoading = false
              this.$buefy.snackbar.open({
								message: this.message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							}) 
            })
          } else {
            await this.$axios.post(`applicants/` + this.applicant.id + `/work-experiences`, this.active_work_experience
            )
            .then(response => {
              this.message = response.data.message
              if (response.data.status) {
								this.work_experiences.push(this.active_work_experience)
								this.refresh()
                this.closeModal()
              }
            })
            .catch(e => {
							this.message = "Error: " + e.response.data.message
            })
            .finally(() => {
				this.minDateEndWork = new Date()
              this.isLoading = false
              this.$buefy.snackbar.open({
								message: this.message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							}) 
            })
          }
        } else {
          this.message = "Gagal! Data kurang/tidak valid, silahkan cek kembali"
          this.$buefy.snackbar.open({
						message: this.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					}) 
        }
        e.preventDefault()
      },
      editWorkExperience(work_experience) {
        this.edited_index = this.work_experiences.indexOf(work_experience)
				this.active_work_experience = Object.assign({}, work_experience)
				this.active_work_experience.temp_start_date = this.active_work_experience.start_work == null
					? null
					: new Date(this.active_work_experience.start_work)
				this.active_work_experience.temp_end_date = this.active_work_experience.end_work == null
					? null 
					: new Date(this.active_work_experience.end_work)
        this.isCardModalActive = true
		this.minDateEndWork = new Date(this.active_work_experience.temp_start_date.getFullYear(), this.active_work_experience.temp_start_date.getMonth(), this.active_work_experience.temp_start_date.getDate() + 1)
			},
			prev() {
				this.setStepperPosition(10)
			},
			onlyNumber($event) {
				let keyCode = $event.keyCode ? $event.keyCode : $event.which;
				if (keyCode < 48 || keyCode > 57) {
					// 46 is dot
					$event.preventDefault();
				}
			},
			onlyAlphabet($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[A-Za-z\s]+$/i.test(char)) { //check if alphabet
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			},
			onlyAlphaNum($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[A-Za-z0-9\s]+$/i.test(char)) { //check if alphabet
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			},
		},
	}
</script>