<template>
	<section>
		<form ref="form" @submit="update" onsubmit="return false;"> 
			<b-modal 
				v-model="isCardModalActive"
				width="auto"
				scroll="keep"
				has-modal-card
				trap-focus
			>
				<div class="card">
					<div class="card-content">
						<b-field
							class="my-6 has-addons has-addons-centered" 
						>
							<b-image
								:src="photo.base64 
									? photo.base64 
									: photo_path 
									+ (applicant.photo 
									? applicant.photo 
									: photo.url 
									? photo.url 
									: 'no_image.jpeg')"
								alt="no image"
								ratio="50x50"
								class="is-128x128"
							>
							</b-image>
						</b-field>
						<b-field>
							<b-upload 
								@input="onFilePicked"
								accept="image/*"
								drag-drop
								native
								expanded
							>
								<section class="section">
									<div class="content has-text-centered">
										<p>Tarik file ke sini atau klik untuk unggah</p>
										<p>(Ukuran maksimal foto 1 MB)</p>
									</div>
								</section>
							</b-upload>
						</b-field>

						<div class="tags is-centered">
							<span
								v-if="photo.name != null"
								class="tag is-primary" >
								{{photo.name}}
							</span>
						</div>
						<b-field>
							<b-button
								type="is-info"
								@click="uploadPhoto"
								:loading="isLoading" 
								expanded
							>
								Unggah
							</b-button>
						</b-field>
						<b-field>
							<b-button
								@click="cancelUploadPhoto"
								expanded
							>
								Batal
							</b-button>
						</b-field>
					</div>
				</div>
			</b-modal>
			<div class="columns is-mobile mt-6">
				<div class="column">
					<b-field>
						<h4 class="title mt-2 is-size-5-desktop is-size-6-mobile is-pulled-left"> 
							1 Data Pribadi
						</h4>
					</b-field>
				</div>
				<div class="column">
					<b-field>
						<b-button  
							class="button is-info is-pulled-right"
							icon-left="refresh" 
							@click="refresh" 
							:loading="isLoading"
						>
						</b-button>
					</b-field>
				</div>
			</div>
			<div class="columns is-desktop">
				<div 
					class="column mt-6"
					v-show="applicant.photo === null"
				>
					<b-notification
						type="is-warning is-light"
						:closable="false"
						role="alert">
						Silahkan upload foto terlebih dahulu agar dapat mengisi form
					</b-notification>
				</div>
			</div>
			<div class="columns is-desktop is-centered">
				<div class="column is-one-quarter-desktop">
					<label class="label has-text-centered">Pas Foto</label>
					<b-field class="mb-6 has-addons has-addons-centered">
						<b-image
							:src="photo.base64 ? photo.base64 : photo_path + (applicant.photo ? applicant.photo : photo.url ? photo.url : 'no_image.jpeg')"
							alt="no image"
							ratio="128x128"
							class="is-128x128"
						>
						</b-image>
					</b-field>
					<b-field class="file">
						<b-button
							class="mt-3"
							type="is-info"
							expanded
							:loading="isLoading" 
							@click="isCardModalActive = true"
						>
							Klik untuk unggah
						</b-button>
					</b-field>	
				</div>
				<div class="column">
					<b-field label="Nama Depan*">
						<b-input 
							validation-message="Silahkan isi bagian ini"
							:disabled="applicant.photo === null"
							placeholder="Nama Depan" 
							v-model="applicant_object.first_name"
							@keypress.native="onlyAlphabet"
							required
						></b-input>
					</b-field>
					<b-field label="Nama Tengah">
						<b-input
							:disabled="applicant.photo === null" 
							placeholder="Nama Tengah" 
							v-model="applicant_object.middle_name"
							@keypress.native="onlyAlphabet"
						></b-input>
					</b-field>
					<b-field label="Nama Belakang">
						<b-input
							:disabled="applicant.photo === null" 
							placeholder="Nama Belakang" 
							v-model="applicant_object.last_name"
							@keypress.native="onlyAlphabet"
						></b-input>
					</b-field>
					<div class="columns is-desktop">
						<div class="column">
							<b-field label="Agama*">
								<b-select 
									:disabled="applicant.photo === null"
									v-model="applicant_object.religion_id" 
									placeholder="Agama" 
									expanded
								>
									<option 
										v-for="religion in religions"
										:value="religion.id"
										:key="religion.id"
									>{{ religion.name }}</option>
								</b-select>
							</b-field>
						</div>
						<div class="column">
							<b-field label="Jenis Kelamin">
								<div class="block">
									<b-radio
										:disabled="applicant.photo === null"
										v-model="applicant_object.gender"
										@input="applicant_object.civil_status_id = null"
										type="is-info"
										native-value="Male"
										name="gender"
										required>
										Laki-Laki
									</b-radio>
									<b-radio
										:disabled="applicant.photo === null"
										v-model="applicant_object.gender"
										@input="applicant_object.civil_status_id = null"
										type="is-info"
										native-value="Female"
										name="gender"
										required>
										Perempuan
									</b-radio>
								</div>
							</b-field>
						</div>
					</div>
					<div class="columns is-desktop">
							<div class="column">
								<b-field label="Tanggal Lahir*">
									<b-datepicker
										:disabled="applicant.photo === null"
										placeholder="Klik untuk memilih tanggal"
										v-model="applicant_object.temp_birth_date"
										@input="saveBirthDate"
										:max-date="maxDate"
										:min-date="minDate"
										:mobile-native="false"
										trap-focus
									></b-datepicker>
								</b-field>
								<b-field label="Kebangsaan*">
										<b-select
											:disabled="applicant.photo === null" 
											v-model="applicant_object.country_id" 
											placeholder="Kebangsaan" 
											expanded
										>
											<option 
												v-for="country in countries"
												:value="country.RECID"
												:key="country.RECID"
											>{{ country.SHORTNAME }}</option>
										</b-select>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Kota Kelahiran*">
									<b-input
										:disabled="applicant.photo === null" 
										v-model="applicant_object.birth_place"
										placeholder="Kota Kelahiran" 
										validation-message="Silahkan isi bagian ini"
										@keypress.native="onlyAlphabet"
										required
									></b-input>
								</b-field>
								<b-field label="Status Sipil*">
									<b-select
										:disabled="applicant.photo === null" 
										v-model="applicant_object.civil_status_id" 
										placeholder="Status Sipil" 
										expanded
										required
									>
										<option 
											v-for="civil_status in civil_statuses"
											:value="civil_status.id"
											:key="civil_status.id"
											:disabled="(applicant_object.gender == 'Female' && civil_status.id == 4)
													|| (applicant_object.gender == 'Male' && civil_status.id == 3)"
											:class="(applicant_object.gender == 'Female' && civil_status.id == 4)
													|| (applicant_object.gender == 'Male' && civil_status.id == 3) ? 'has-background-grey':''"
										>{{ civil_status.name }}</option>
									</b-select>
								</b-field>
							</div>
							<div 
								v-show="applicant_object.civil_status_id == 1"  
								class="column">
								<b-field label="Tanggal Pernikahan">
									<b-datepicker
										:disabled="applicant.photo === null"
										placeholder="Klik untuk memilih tanggal"
										v-model="applicant_object.temp_wedding_date"
										@input="saveWeddingDate"
										:max-date="maxDate"
										:min-date="minDate"
										:mobile-native="false"
										trap-focus
									></b-datepicker>
								</b-field>
							</div>
						</div>
					<b-field>
						<b-button 
							:disabled="applicant.photo === null" 
							class="button is-info"
							:loading="isLoading" 
							@click="next()" 
							expanded
						>Simpan & Lanjut</b-button>
					</b-field>
				</div>
			</div>
		</form>
	</section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { dateFormat } from '@/plugins/timeformat.js'
export default {
  data() {
		const today = new Date()
    return {
			isCardModalActive: false,
			applicant_object: {
				id: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        gender: null,
        religion_id: null,
        country_id: null,
        birth_place: null,
        birth_date: null,
				civil_status_id: null,
				wedding_date: null
			},
			religions: [],
			civil_statuses: [],
			countries: [],
      photo: {
				name: null,
				url: null,
				base64: null,
				file: null,
				is_editing: false
			},
			photo_path: process.env.VUE_APP_PHOTO_URL,
			hris_laravel_path: process.env.VUE_APP_HRIS_LARAVEL_URL,
			dropFiles: [],
			
			minDate: new Date(today.getFullYear() - 70, today.getMonth(), today.getDate()),
			maxDate: new Date(today.getFullYear() + 18, today.getMonth(), today.getDate())
    };
	},
	computed: {
		...mapGetters({
			applicant: "applicant",
			stepper_position: 'stepper_position',
			loading: 'loading'
		}),

		isLoading: {
			get() {
				return this.loading
			},

			set(val) {
				this.setLoading(val)
			}
		}
	},
	mounted() {
		this.fetchApplicantData()
	},
  methods: {
		...mapActions({
			setApplicant: "setApplicant",
			setStepperPosition: "setStepperPosition",
			setLoading: 'setLoading'
		}),

		async fetchApplicantData(){
			this.isLoading = true;
			await this.$axios
        .get(`applicants/` + this.applicant.id)
        .then(response => {
					const data = response.data.data;
					this.applicant_object = {
						...data,
						temp_birth_date: data.birth_date == null ? null : new Date(data.birth_date),
						temp_wedding_date: data.wedding_date == null ? null : new Date(data.wedding_date)
					}
        })
        .catch(e => {
          this.message = "Error: " + e.response.data.message;
          this.$buefy.snackbar.open({
						message: this.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
        })
        .finally(() => {
          // this.isLoading = false;
        });
			const civilStatusesRes = await this.$axios.get(`civil-statuses`);
			const religionsRes = await this.$axios.get(`religions`);
			const countriesRes = await this.$axios.post(
				process.env.VUE_APP_API_URL + "master-gateway", {
					code: "M1"
				}
			);

			await Promise.all([civilStatusesRes, religionsRes, countriesRes])
				.then(response => {
					this.civil_statuses = response[0].data.data;
					this.religions = response[1].data.data;
					
					this.countries = response[2].data;

					if (this.applicant_object.country_id == null) {
            const tempCountry = this.countries.find(
              item => item.RECID == 5637148630
            );
            this.applicant_object.country_id = tempCountry.RECID;
            this.applicant_object.country_name = tempCountry.SHORTNAME;
          }
				})
				.catch(e => {
					this.message = "Error: " + e.response.data.message;
					this.$buefy.snackbar.open({
						message: this.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				})
				.finally(() => {
					this.isLoading = false;
				})
		},
		async refresh() {
			await this.fetchApplicantData();
		},
		saveBirthDate() {
			this.applicant_object.birth_date = dateFormat(this.applicant_object.temp_birth_date)
		},
		saveWeddingDate() {
			this.applicant_object.wedding_date = dateFormat(this.applicant_object.temp_wedding_date)
		},
		update () {
			// delete leading spaces
			this.applicant_object.first_name = this.applicant_object.first_name ? this.applicant_object.first_name.replace(/^\s*/, "") : null
			this.applicant_object.middle_name = this.applicant_object.middle_name ? this.applicant_object.middle_name.replace(/^\s*/, "") : null
			this.applicant_object.last_name = this.applicant_object.last_name ? this.applicant_object.last_name.replace(/^\s*/, "") : null
			this.applicant_object.birth_place = this.applicant_object.birth_place ? this.applicant_object.birth_place.replace(/^\s*/, "") : null
			
			if(this.applicant_object.civil_status_id == 1 && this.applicant_object.temp_wedding_date == null) {
				this.message = "Gagal! Data kurang/tidak valid, silahkan cek kembali"
				this.$buefy.snackbar.open({
					message: this.message,
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
				return false
			} else {
				if (this.$refs.form.checkValidity()) {
					// check if name contain special characters
					if (!(/^[A-Za-z\s]+$/i.test(this.applicant_object.first_name) && 
					(!this.applicant_object.middle_name || /^[A-Za-z\s]+$/i.test(this.applicant_object.middle_name)) && 
					(!this.applicant_object.last_name || /^[A-Za-z\s]+$/i.test(this.applicant_object.last_name)))) {
                        this.$buefy.snackbar.open({
                                message: "Gagal! Nama hanya bisa alfabet dan spasi",
                                type: 'is-info',
                                position: 'is-top',
                                actionText: 'Tutup',
                                indefinite: false,
                            })
                        return false;
                    }

					if (!(/^[A-Za-z\s]+$/i.test(this.applicant_object.birth_place))) { // check if birth place contain other than alfabet and space
						this.$buefy.snackbar.open({
                                message: "Gagal! Kota kelahiran hanya bisa alfabet dan spasi",
                                type: 'is-info',
                                position: 'is-top',
                                actionText: 'Tutup',
                                indefinite: false,
                            })
                        return false;
					}

					this.message = null
					
					this.$axios.patch(`applicants/` + this.applicant.id, this.applicant_object)
					.then(response => {
						this.message = response.data.message
						return true
					})
					.catch(e => {
						this.message = "Error: " + e.response.data.message
						return false
					})
					.finally(() => {
						this.save_button_loading = false
						this.$buefy.snackbar.open({
							message: this.message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
						return true
					})
				} else {
					this.message = "Gagal! Data kurang/tidak valid, silahkan cek kembali"
					this.$buefy.snackbar.open({
						message: this.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
					return false
				}
			}
		},
		uploadPhoto (e) {	
			if (this.photo.file) {
				this.isLoading = true
				let form_data = new FormData();
				form_data.append('file', this.photo.file);
				// axios.post(this.hris_laravel_path + `career/applicant/` + this.applicant.career_applicant_id + '/photo/upload', form_data)
				this.axios.post(`applicants/${this.applicant.id}/photo/update`, form_data)
				.then(response => {
					this.message = response.data.message
					this.applicant.photo = response.data.data;
				})
				.catch(e => {
					this.message = "Error: " + e.response.data.message
				})
				.finally(() => {
					this.isLoading = false
					this.photo.is_editing = false
					this.$buefy.snackbar.open({
						message: this.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
					this.isCardModalActive = false
				})
			} else {
				this.message = 'Tidak ada foto terpilih'
				this.$buefy.snackbar.open({
					message: this.message,
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
			}
			e.preventDefault()
		},
		onFilePicked (file) {
			const files = file
			if(files !== undefined) {
				this.photo.name = files.name
				if(this.photo.name.lastIndexOf(".") <= 0) {
					this.$buefy.snackbar.open({
						message: 'Error: ',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
					return;
				}
				const fr = new FileReader ()
				fr.readAsDataURL(files)
				fr.addEventListener("load", () => {
					if (files.size > 1000000) {
						this.photo.name = null
						this.photo.file = null
						this.photo.url = null
						this.$buefy.snackbar.open({
							message: 'Ukuran file terlalu besar, maksimal 1 megabytes',
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					} 
					else {
						this.photo.base64 = fr.result
						this.photo.file = files // this is an image file that can be sent to server...
					}
				})
			} else {
				this.$buefy.snackbar.open({
					message: 'Error: ',
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
				this.photo.name = null
				this.photo.file = null
				this.photo.url = null
			}
		},
		cancelUploadPhoto() {
			this.isCardModalActive = false;
			this.photo = {
				name: null,
				url: null,
				base64: null,
				file: null
			};
		},
		async next() {
			var response = await this.update();
			
			if (response != false) {
				this.setStepperPosition(1)
			}
		},
		onlyAlphabet($event) {
            let char = String.fromCharCode($event.keyCode);
            if (/^[A-Za-z\s]+$/i.test(char)) { //check if alphabet
				return true;
			} else {
				$event.preventDefault();
				return false;
			}
        }
  }
};
</script>