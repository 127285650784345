<template>
  <section>
    <b-field>
      <b-button class="is-text" icon-left="chevron-left" @click="prev()">
        Sebelumnya
      </b-button>
    </b-field>
    <div class="columns">
      <div class="column">
        <h4 class="title is-size-5-desktop is-size-6-mobile is-pulled-left">
          7 Kontak Darurat Selain Keluarga Inti
        </h4>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-notification
          type="is-danger is-light"
          aria-close-label="Close notification"
          role="alert"
          :closable="false"
        >
          <p class="is-size-7-mobile">Ketentuan pengisian form:</p>
          <p class="is-size-7-mobile has-icon-left has-text-weight-semibold">
            <b-icon icon="check" size="is-small"></b-icon>
            Kontak Darurat Wajib Diisi
          </p>
        </b-notification>
      </div>
    </div>
    <div class="columns is-desktop">
      <div class="column is-one-quarter is-offset-9">
        <b-field>
          <b-button
            class="button is-info is-pulled-right"
            @click="refresh"
            :loading="isLoading"
          >
            Perbarui
          </b-button>
        </b-field>
      </div>
    </div>
    <form ref="form">
      <div class="columns is-desktop mt-4">
        <div class="column is-two-quarter">
          <b-field label="Hubungan Keluarga*">
            <b-select
              v-model="active_relation.relation_type_id"
              @input="updateRelationName"
              placeholder="Hubungan Keluarga"
              expanded
              validation-message="Silahkan isi bagian ini"
              required
            >
              <option
                v-for="type in relation_type"
                :key="type.id"
                :value="type.id"
              >
                {{ type.name }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Nomor Handphone*">
            <b-input
              placeholder="Nomor Handphone (Min. 10 Angka)"
              @keypress.native="onlyNumber"
              v-model="active_relation.phone_number"
              maxlength="13"
              minlength="10"
              validation-message="Silahkan isi dengan nomor handphone yang aktif"
              required
            ></b-input>
          </b-field>
        </div>
        <div class="column is-vcentered">
          <b-field label="Nama Lengkap*">
            <b-input
              placeholder="Nama Lengkap"
              v-model="active_relation.name"
              validation-message="Nama tidak boleh kosong!"
              @keypress.native="onlyAlphabet"
              required
            ></b-input>
          </b-field>
          <b-field label="Jenis Kelamin*">
            <div class="block">
              <b-radio
                v-model="active_relation.gender"
                name="gender"
                class="mt-2"
                type="is-info"
                native-value="L"
                required
              >
                Laki-Laki
              </b-radio>
              <b-radio
                v-model="active_relation.gender"
                name="gender"
                type="is-info"
                native-value="P"
                required
              >
                Perempuan
              </b-radio>
            </div>
          </b-field>
        </div>
      </div>
      <b-field label="Alamat">
        <b-input
          type="textarea"
          placeholder="Alamat"
          v-model="active_relation.address"
          :maxlength="maxInputEmergencyContactAddress"
          @keypress.native="onlyAlphaNum"
        ></b-input>
      </b-field>
      <b-field class="mt-10">
        <b-button
          class="button is-info mt-5"
          @click="next"
          :loading="isLoading"
          expanded
        >
          Simpan & Lanjut
        </b-button>
      </b-field>
    </form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
      edited_index: -1,
      maxInputEmergencyContactAddress:80,

      relations: [],
      relation_type: [],
      active_relation: {
        id: null,
        name: null,
        gender: null,
        birth_place: null,
        birth_date: null,
        phone_number: null,
        job: null,
        email: null,
        address: null,
        last_education: null,
        position: null,
        company: null,
        civil_status: null,
        life_status: "Hidup",
        medical_history: null,
        applicant_id: null,
        relation_type: {
          id: null,
          name: null,
          status: null,
        },
      },
      active_relation_default: {
        id: null,
        name: null,
        gender: null,
        birth_place: null,
        birth_date: null,
        phone_number: null,
        job: null,
        email: null,
        address: null,
        last_education: null,
        position: null,
        company: null,
        civil_status: null,
        life_status: "Hidup",
        medical_history: null,
        applicant_id: null,
        relation_type: {
          id: null,
          name: null,
          status: null,
        },
      },
    };
  },
  async mounted() {
    await this.refresh();
  },
  computed: {
    ...mapGetters({
      applicant: "applicant",
      stepper_position: "stepper_position",
    }),
  },
  methods: {
    ...mapActions({
      setApplicant: "setApplicant",
      setStepperPosition: "setStepperPosition",
    }),
    next() {
      this.active_relation.name = this.active_relation.name ? this.active_relation.name.replace(/^\s*/, "") : null
      this.active_relation.address = this.active_relation.address ? this.active_relation.address.replace(/^\s*/, "") : null
      this.active_relation.address = this.active_relation.address != null ? this.active_relation.address.length == 1 && (this.active_relation.address == 0 || this.active_relation.address == "0") ? "" : this.active_relation.address : null
      this.active_relation.address = this.active_relation.address != null ? this.active_relation.address.length > 1 && this.active_relation.address.trim().length === 1 && (this.active_relation.address.charAt(0) == 0 || this.active_relation.address.charAt(0) == "0") ? "" : this.active_relation.address : null

      if (this.$refs.form.checkValidity()) {
        if (!(/^[0-9]+$/i.test(this.active_relation.phone_number))) {
          this.$buefy.snackbar.open({
            message: "Gagal! Nomor handphone hanya bisa angka",
            type: 'is-info',
            position: 'is-top',
            actionText: 'Tutup',
            indefinite: false,
          })
        return false;
        }
        if (!(/^[A-Za-z\s]+$/i.test(this.active_relation.name))) {
          this.$buefy.snackbar.open({
            message: "Gagal! Nama hanya bisa alfabet dan spasi",
            type: 'is-info',
            position: 'is-top',
            actionText: 'Tutup',
            indefinite: false,
          })
        return false;
        }
        if (this.active_relation.address && !(/^[A-Za-z0-9\s.,]+$/i.test(this.active_relation.address))) {
          this.$buefy.snackbar.open({
            message: "Gagal! Alamat hanya bisa alfabet, angka, tanda titik, koma, dan spasi",
            type: 'is-info',
            position: 'is-top',
            actionText: 'Tutup',
            indefinite: false,
          })
        return false;
        }

        this.isLoading = true;
        this.message = null;

        this.active_relation.applicant_id = this.applicant.id;

        this.$axios
          .post(
            `applicants/` + this.applicant.id + `/relations/new`,
            this.active_relation
          )
          .then((response) => {
            this.message = response.data.message;
          })
          .catch((e) => {
            this.message = "Error: " + e.response.data.message;
            return false;
          })
          .finally(() => {
            this.isLoading = false;
            this.$buefy.snackbar.open({
              message: this.message,
              type: "is-info",
              position: "is-top",
              actionText: "Tutup",
              indefinite: false,
            });
            this.setStepperPosition(7);
          });
      } else {
        this.message =
          "Gagal! Data kosong/kurang/tidak valid, silahkan cek kembali";
        this.$buefy.snackbar.open({
          message: this.message,
          type: "is-info",
          position: "is-top",
          actionText: "Tutup",
          indefinite: false,
        });
        return false;
      }
    },
    refresh() {
      this.isLoading = true;
      this.active_relation = this.active_relation_default;

      this.$axios
        .get("applicants/" + this.applicant.id + `/relations`)
        .then((response) => {
          this.relations = response.data.data.filter(function(object) {
            return parseInt(object.relation_type.status) === 2;
          });

          if (this.relations.length > 0) {
            this.active_relation = this.relations[this.relations.length - 1];
          }
        })
        .catch((e) => {
          this.message = "Error: " + e.response.data.message;
          this.$buefy.snackbar.open({
            message: this.message,
            type: "is-info",
            position: "is-top",
            actionText: "Tutup",
            indefinite: false,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });

      if (!this.relation_type.length) {
        this.isLoading = true;
        this.$axios
          .get("relation-types")
          .then((response) => {
            this.relation_type = response.data.data.filter(function(object) {
              return parseInt(object.status) === 2;
            });
          })
          .catch((e) => {
            this.message = "Error: " + e.response.data.message;
            this.$buefy.snackbar.open({
              message: this.message,
              type: "is-info",
              position: "is-top",
              actionText: "Tutup",
              indefinite: false,
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    updateRelationName() {
      for (var i = 0; i < this.relation_type.length; i++) {
        if (
          this.relation_type[i].id === this.active_relation.relation_type_id
        ) {
          this.active_relation.relation_type.name = this.relation_type[i].name;
          break;
        }
      }
    },
    prev() {
      this.setStepperPosition(5);
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    onlyAlphabet($event) {
      let char = String.fromCharCode($event.keyCode);
      if (/^[A-Za-z\s]+$/i.test(char)) { //check if alphabet
        return true;
      } else {
        $event.preventDefault();
        return false;
      }
    },
    onlyAlphaNum($event) {
      let char = String.fromCharCode($event.keyCode);
      if (/^[A-Za-z0-9\s.,]+$/i.test(char)) { //including dot and coma
        return true;
      } else {
        $event.preventDefault();
        return false;
      }
    }
  },
};
</script>
