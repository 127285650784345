<template>
	<section>
		<b-modal 
			v-model="isCardModalActive"
			width="auto"
			scroll="keep"
			has-modal-card
			trap-focus
		>
			<form ref="form">
				<div class="modal-card" style="width: auto">
					<header class="modal-card-head">
						<p class="title is-size-5-desktop is-size-6-mobile">{{ formTitle }}</p>
					</header>
					<section class="modal-card-body">
						<div class="columns is-desktop">
							<div class="column">
								<b-field label="Jenis Kendaraan*">
									<b-select  
										v-model="active_vehicle.type" 
										placeholder="Jenis Kendaraan" 
										expanded
									>
										<option 
											v-for="vehicle in vehicle_type"
											:key="vehicle"
										>{{ vehicle }}</option>
									</b-select>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Kepemilikan*">
									<b-select  
										v-model="active_vehicle.owner_type" 
										placeholder="Kepemilikan" 
										expanded
									>
										<option 
											v-for="ownership in ownership"
											:key="ownership"
										>{{ ownership }}</option>
									</b-select>
								</b-field>
							</div>
						</div>
						<div class="columns is-desktop">
							<div class="column">
								<b-field label="Merk*">
									<b-input
										v-model="active_vehicle.brand"
										placeholder="Merk"
										validation-message="Silahkan isi bagian ini"
										@keypress.native="onlyAlphaNum"
										required
									></b-input>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Tahun Produksi*">
									<b-input
										@keypress.native="onlyNumber"
										maxlength="4"
										minlength="4"
										v-model="active_vehicle.production_year"
										placeholder="Tahun Produksi"
										validation-message="Silahkan isi Tahun Produksi (Angka)"
										required>
									</b-input>
								</b-field>
							</div>
						</div>
					</section>
					<footer class="modal-card-foot is-pulled-right">
						<b-button 
							class="is-pulled-right"  
							@click="closeModal()"
						>Kembali</b-button>
						<b-button 
							class="is-pulled-right"
							type="is-info" 
							@click.prevent="saveVehicle"
							:loading="isLoading"
						>Simpan</b-button>
					</footer>
				</div>
			</form>
		</b-modal>
		<b-field>
			<b-button
				class="is-text"
				icon-left="chevron-left"
				@click="prev()"
			>
				Sebelumnya
			</b-button>
		</b-field>
		<div class="columns">
			<div class="column">
				<h4 class="title is-size-5-desktop is-size-6-mobile is-pulled-left"> 
					4 Kendaraan
				</h4>
			</div>
		</div>
		<div class="columns is-desktop">
			<div class="column is-one-quarter is-offset-9">
				<b-field>
					<b-button  
						class="button is-success is-pulled-right" 
						@click="add()" 
						:loading="isLoading"
						expanded
					>
						Tambah Data
					</b-button>
				</b-field>
			</div>
		</div>
		<b-table 
			class="box mt-6"
			:data="vehicles.length == 0 ? empty : vehicles"
			:loading="isLoading" 
		>
			<b-table-column
				label="Data Kendaraan"
				:visible="vehicles.length == 0"
				v-slot="props" 
			>
				{{ props.row }}
			</b-table-column>
			<b-table-column
				label="Jenis Kendaraan"
				:visible="vehicles.length != 0"
				v-slot="props" 
			>
				{{ 
					props.row.type + " - " + 
					props.row.brand + " (" + 
					props.row.production_year + ")" 
				}}
			</b-table-column>
			<b-table-column
				:visible="vehicles.length != 0"
				field="action"
				label="Aksi"
				v-slot="props" 
				centered
			>
				<b-button  
					class="button is-warning is-light" 
					@click="editVehicle(props.row)"
				>Ubah</b-button>
			</b-table-column>
		</b-table>
		<b-field>
			<b-button  
				class="button is-info" 
				@click="next()" 
				expanded
				:loading="isLoading"
			>
				Simpan & Lanjut
			</b-button>
		</b-field>
	</section>
</template>

<script>
	import { mapGetters, mapActions } from "vuex";

	export default {
		data() {
			return {
				isCardModalActive: false,
				isLoading: false,
				vehicle_type: [
          'Mobil',
          'Motor'
				],
				ownership: [
					'Sendiri', 
					'Kantor', 
					'Orang Tua', 
					'Lain-Lain'
				],
				empty: [
					'[Tidak Wajib] Jika tidak ada silahkan lanjutkan ke halaman selanjutnya'
				],
				vehicles: [],
				active_vehicle: {
          id: null,
          type: null,
          brand: null,
          production_year: null,
          owner_type: null,
          applicant_id: null
        },
        active_vehicle_default: {
          id: null,
          type: null,
          brand: null,
          production_year: null,
          owner_type: null,
          applicant_id: null
				},
				edited_index: -1,
			}
		},
		mounted() {
			this.refresh();
		},
		computed: {
			...mapGetters ({
				applicant: "applicant",
				stepper_position: "stepper_position"
			}),
			formTitle() {
				return this.edited_index === -1 
					? 'Tambah Data Kendaraan' 
					: 'Ubah Data Kendaraan'
			}
		},
		methods: {
			...mapActions ({
				setApplicant: 'setApplicant',
				setStepperPosition: 'setStepperPosition'
			}),
			async next() {
        await this.$axios.get('applicants/' + this.applicant.id + '/identities')
        .then(response => {
					this.identities = response.data.data
        })
        .catch(e => {
          this.message = "Error: " + e.response.data.message
          this.$buefy.snackbar.open({
						message: this.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
        })
        .finally(() => {
          this.isLoading = false
				})
				
				const foundSimA = this.identities.some(item => item.identity_type_id == 7)
        const foundSimB1 = this.identities.some(item => item.identity_type_id == 8)
        const foundSimB2 = this.identities.some(item => item.identity_type_id == 9)
        const foundSimC = this.identities.some(item => item.identity_type_id == 10)
        const foundCar = this.vehicles.some(item => item.type == "Mobil")
        const foundBike = this.vehicles.some(item => item.type == "Motor")
        
        if (foundSimA && !foundCar) {
					this.$buefy.snackbar.open({
						message: "Data SIM A ditemukan, silahkan mengisi kendaraan terlebih dahulu",
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} else if (foundSimB1 && !foundCar) {
					this.$buefy.snackbar.open({
						message: "Data SIM B1 ditemukan, silahkan mengisi kendaraan terlebih dahulu",
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				}
        else if (foundSimB2 && !foundCar) {
					this.$buefy.snackbar.open({
						message: "Data SIM B2 ditemukan, silahkan mengisi kendaraan terlebih dahulu",
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				}
        else if (foundSimC && !foundBike) {
					this.$buefy.snackbar.open({
						message: "Data SIM C ditemukan, silahkan mengisi kendaraan terlebih dahulu",
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				}
        else {
					this.setStepperPosition(4)
				}
      },
			refresh() {
				this.isLoading = true
        this.$axios.get('applicants/' + this.applicant.id + '/vehicles')
        .then(response => {
          // console.log(response.data.data)
          this.vehicles = response.data.data
        })
        .catch(e => {
          this.message = "Error: " + e.response.data.message
          this.$buefy.snackbar.open({
						message: this.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
        })
        .finally(() => {
          this.isLoading = false
        })
			},
			add() {
				this.isCardModalActive = true
				this.active_vehicle = Object.assign({}, this.active_vehicle_default)
        this.edited_index = -1
			},
			saveVehicle(e) {
				// delete leading spaces
				this.active_vehicle.brand = this.active_vehicle.brand ? this.active_vehicle.brand.replace(/^\s*/, "") : null
				this.active_vehicle.brand = this.active_vehicle.brand != null ? this.active_vehicle.brand.length == 1 && (this.active_vehicle.brand == 0 || this.active_vehicle.brand == "0") ? "" : this.active_vehicle.brand : null
this.active_vehicle.brand = this.active_vehicle.brand != null ? this.active_vehicle.brand.length > 1 && this.active_vehicle.brand.trim().length === 1 && (this.active_vehicle.brand.charAt(0) == 0 || this.active_vehicle.brand.charAt(0) == "0") ? "" : this.active_vehicle.brand : null

			if (this.$refs.form.checkValidity()) {
				if (!(/^[A-Za-z0-9\s]+$/i.test(this.active_vehicle.brand))) {
					this.$buefy.snackbar.open({
							message: "Gagal! Merk hanya bisa angka, alfabet dan spasi",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
				}
				if (!(/^[0-9]+$/i.test(this.active_vehicle.production_year))) {
					this.$buefy.snackbar.open({
							message: "Gagal! Tahun produksi hanya bisa angka",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
				}

				this.isLoading = true
				this.active_vehicle.applicant_id = this.applicant.id
				this.message = null
				
				if (this.edited_index > -1) {
					this.$axios.patch(`applicants/` + this.applicant.id + `/vehicles/` + this.active_vehicle.id, this.active_vehicle)
					.then(response => {
						this.message = response.data.message
						
						if (response.data.status) {
							Object.assign(this.vehicles[this.edited_index], this.active_vehicle)
							this.closeModal()
						}
					})
					.catch(e => {
						this.message = "Error: " + e.response.data.message
					})
					.finally(() => {
						this.$buefy.snackbar.open({
							message: this.message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
						})
						this.isLoading = false
					})
				} else {
					this.$axios.post(`applicants/` + this.applicant.id + `/vehicles`, this.active_vehicle
					)
					.then(response => {
						this.message = response.data.message
						
						if (response.data.status) {
							this.active_vehicle.id = response.data.data.id
							this.vehicles.push(this.active_vehicle)
							this.closeModal()
						}
					})
					.catch(e => {
						this.message = "Error: " + e.response.data.message
					})
					.finally(() => {
						this.$buefy.snackbar.open({
							message: this.message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
						})
						this.isLoading = false
					})
				}
			} else {
				this.message = "Gagal! Data kurang/tidak valid, silahkan cek kembali"
				this.$buefy.snackbar.open({
					message: this.message,
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
			}
				e.preventDefault()
			},
			editVehicle(vehicle) {
				this.edited_index = this.vehicles.indexOf(vehicle)
        this.active_vehicle = Object.assign({}, vehicle)
        this.isCardModalActive = true
			},
			closeModal() {
				this.isCardModalActive = false
        this.active_vehicle = Object.assign({}, this.active_vehicle_default)
        this.edited_index = -1
			},
			prev() {
				this.setStepperPosition(2)
			},
			onlyNumber($event) {
				let keyCode = $event.keyCode ? $event.keyCode : $event.which;
				if (keyCode < 48 || keyCode > 57) {
					// 46 is dot
					$event.preventDefault();
				}
			},
			onlyAlphaNum($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[A-Za-z0-9\s]+$/i.test(char)) {
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			}
		},
	}
</script>