<template>
	<section>
		<b-modal 
			v-model="isCardModalActive"
			width="auto"
			scroll="keep"
			has-modal-card
			trap-focus
		>
			<form ref="form">
				<div class="modal-card" style="width: auto">
					<header class="modal-card-head">
						<p class="title is-size-5-desktop is-size-6-mobile">{{ formTitle }}</p>
					</header>
					<section class="modal-card-body">
						<div class="columns is-desktop">
							<div class="column">
								<b-field label="Hubungan Keluarga*">
									<b-select  
										v-model="active_relation.relation_type_id" 
										@input="updateRelationName"
										placeholder="Hubungan Keluarga" 
										expanded
									>
										<option 
											v-for="relation in relation_types"
											:key="relation.id"
											:value="relation.id"
											:disabled="(applicant.civil_status_id == 2 && relation.id >= 4 && relation.id <= 8)
												|| (applicant.gender == 'Female' && (relation.id == 5 || relation.id == 7))
												|| (applicant.gender == 'Male' && (relation.id == 4 || relation.id == 6))
												|| (applicant.civil_status_id == 3 && (relation.id == 4 || relation.id == 5 || relation.id ==7))
												|| (applicant.civil_status_id == 4 && (relation.id == 4 || relation.id == 5 || relation.id == 6))"
											:class="(applicant.civil_status_id == 2 && relation.id >= 4 && relation.id <= 8)
												|| (applicant.gender == 'Female' && (relation.id == 5 || relation.id == 7))
												|| (applicant.gender == 'Male' && (relation.id == 4 || relation.id == 6))
												|| (applicant.civil_status_id == 3 && (relation.id == 4 || relation.id == 5 || relation.id ==7))
												|| (applicant.civil_status_id == 4 && (relation.id == 4 || relation.id == 5 || relation.id == 6)) ? 'has-background-grey' : ''"
										>{{ relation.name }}</option>
									</b-select>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Status Sipil*">
									<b-select  
										v-model="active_relation.civil_status_id" 
										placeholder="Status Sipil" 
										expanded
										validation-message="Silahkan isi bagian ini"
										required
									>
										<option 
											v-for="status in filterCivilStatuses"
											:key="status.id"
											:value="status.id"
										>{{ status.name }}</option>
									</b-select>
								</b-field>
							</div>
						</div>
						<b-field label="Nama Lengkap*">
							<b-input
								v-model="active_relation.name"
								placeholder="Nama Lengkap"
								validation-message="Silahkan isi bagian ini"
								@keypress.native="onlyAlphabet"
								required
							></b-input>
						</b-field>
						<div class="columns is-desktop">
							<div class="column">
								<b-field label="Kota Kelahiran*">
									<b-input
										v-model="active_relation.birth_place"
										placeholder="Kota Kelahiran"
										validation-message="Silahkan isi bagian ini"
										@keypress.native="onlyAlphabet"
										required
									></b-input>
								</b-field>
								<b-field label="Status Hidup*">
									<b-select  
										v-model="active_relation.life_status" 
										placeholder="Status Hidup" 
										expanded
										validation-message="Silahkan isi bagian ini"
										required
									>
										<option value="Hidup"
										>Hidup</option>
										<option value="Meninggal"
										>Meninggal</option>
									</b-select>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Tanggal Lahir*">
									<b-datepicker
										placeholder="Klik untuk memilih tanggal"
										v-model="active_relation.temp_birth_date"
										@input="saveBirthDate"
										:max-date="maxDate"
										:min-date="minDate"
										:mobile-native="false"
										trap-focus
										required
									></b-datepicker>
								</b-field>
								<b-field label="Jenis Kelamin*">
									<div class="block">
										<b-radio
											v-model="active_relation.gender"
											name="gender"
											type="is-info"
											native-value="L"
											required>
											Laki-Laki
										</b-radio>
										<b-radio
											v-model="active_relation.gender"
											name="gender"
											type="is-info"
											native-value="P"
											required>
											Perempuan
										</b-radio>
									</div>
								</b-field>
							</div>
						</div>
						<div class="columns is-desktop">
							<div class="column">
							<b-field label="Pendidikan Terakhir*">
									<b-select  
										v-model="active_relation.education_level_id" 
										placeholder="Pendidikan Terakhir" 
										expanded
										required
									>
										<option 
											v-for="level in education_levels"
											:key="level.id"
											:value="level.id"
										>{{ level.name }}
										</option>
									</b-select>
								</b-field>
								<b-field label="Perusahaan">
									<b-input
										v-model="active_relation.company"
										placeholder="Perusahaan"
										validation-message="Silahkan isi bagian ini"
										@keypress.native="onlyAlphaNum"
										maxlength="50"
									></b-input>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Pekerjaan*">
									<b-input
										v-model="active_relation.position"
										placeholder="Pekerjaan"
										validation-message="Silahkan isi bagian ini"
										@keypress.native="onlyAlphaNum"
										maxlength="50"
										required
									></b-input>
								</b-field>
								<b-field label="Nomor Handphone">
									<b-input
										@keypress.native="onlyNumber"
										v-model="active_relation.phone_number"
										placeholder="Nomor Handphone (Min. 10 Angka)"
										maxlength="13"
										minlength="10"
									></b-input>
								</b-field>
							</div>
						</div>
					</section>
					<footer class="modal-card-foot is-pulled-right">
						<b-button 
							class="is-pulled-right"  
							@click="closeModal()"
						>Kembali</b-button>
						<b-button 
							class="is-pulled-right"
							type="is-info" 
							@click.prevent="saveRelation"
              :loading="isLoading"
						>Simpan</b-button>
					</footer>
				</div>
			</form>
		</b-modal>
		<b-field>
			<b-button
				class="is-text"
				icon-left="chevron-left"
				@click="prev()"
			>
				Sebelumnya
			</b-button>
		</b-field>
		<div class="columns">
			<div class="column">
				<h4 class="title is-size-5-desktop is-size-6-mobile is-pulled-left"> 
					6 Keluarga Inti
				</h4>
			</div>
		</div>
		<div class="columns">
			<div class="column">
				<b-notification					
					type="is-danger is-light"
					aria-close-label="Close notification"
					role="alert"
					:closable="false">
					<p class="is-size-7-mobile">Ketentuan pengisian form:</p> 
					<p class="is-size-7-mobile has-icon-left has-text-weight-semibold">
						<b-icon 
							icon="check"
							size="is-small"
						></b-icon> 
						Mohon data diri (Nama Anda Sendiri) TIDAK PERLU di input
					</p>
					<p class="is-size-7-mobile has-icon-left has-text-weight-semibold">
						<b-icon 
							icon="check"
							size="is-small"
						></b-icon> 
						Data Orang Tua dan Saudara Kandung Wajib Diisi (Jika Anda sebagai Anak Tunggal maka TIDAK PERLU isi saudara kandung)
					</p>
					<p class="is-size-7-mobile has-icon-left has-text-weight-semibold">
						<b-icon 
							icon="check"
							size="is-small"
						></b-icon> 
						Jika Anda memiliki saudara kandung, Mohon untuk nama anda sendiri TIDAK PERLU di input
					</p>
					<p class="is-size-7-mobile has-icon-left has-text-weight-semibold">
						<b-icon 
							icon="check"
							size="is-small"
						></b-icon> 
						Jika Anda Sudah Menikah, Silahkan Mengisi Keluarga Inti Sesuai KK (Jika Anda sebagai Istri, maka Input HANYA Data Suami dan Anak Anda / Jika Anda sebagai Suami, maka Input HANYA Data Istri dan Anak Anda)
					</p>
				</b-notification>
			</div>
		</div>
		<div class="columns is-desktop">
			<div class="column is-one-quarter is-offset-9">
				<b-field>
					<b-button  
						class="button is-success is-pulled-right"
						:loading="isLoading"
						@click="add()" 
						expanded
					>
						Tambah Data
					</b-button>
				</b-field>
			</div>
		</div>
		<b-table 
			class="box mt-6"
			:data="relations.length == 0 ? empty : relations"
			:loading="isLoading"
		>
			<b-table-column
				label="Data Keluarga"
				:visible="relations.length == 0"
				v-slot="props" 
			>
				{{ props.row }}
			</b-table-column>
			<b-table-column
				label="Nama"
				:visible="relations.length != 0"
				v-slot="props" 
			>
				{{ 
					props.row.name + " (" + 
					props.row.relation_type.name + ")"  
				}}
			</b-table-column>
			<b-table-column
				:visible="relations.length != 0"
				field="action"
				label="Aksi"
				v-slot="props" 
				centered
			>
				<b-button  
					class="button is-warning is-light" 
					@click="editRelation(props.row)"
				>Ubah</b-button>
			</b-table-column>
		</b-table>
		<b-field>
			<b-button  
				class="button is-info" 
				@click="next()" 
				:loading="isLoading"
				expanded
			>
				Simpan & Lanjut
			</b-button>
		</b-field>
	</section>
</template>

<script>
	import { mapGetters, mapActions } from "vuex"
	import { dateFormat } from '@/plugins/timeformat.js'
	export default {
		data() {
			const today = new Date()
			return {
				isCardModalActive: false,
				isLoading: false,
				edited_index: -1,
				empty: ['Belum ada data'],
				civil_statuses: [
          { id: 1, name: 'Kawin' },
          { id: 2, name: 'Tidak Kawin' },
          { id: 3, name: 'Janda' },
          { id: 4, name: 'Duda' },
        ],
        relation_types: [],
				education_levels: [],
				relations: [],
        active_relation: {
					id: null,
          name: null,
          gender: null,
          birth_place: null,
          birth_date: null,
          phone_number: null,
          job: null,
          email: null,
          address: null,
          education_level_id: null,
          education_level: {
            name: null
          },
          position: null,
          company: null,
          civil_status_id: null,
          civil_status: {
            name: null
          },
          life_status: 'Hidup',
          medical_history: null,
          applicant_id: null,
          relation_type_id: null,
          relation_type: {
            id: null,
            name: null,
            status: null
          }
        },
        active_relation_default: {
          id: null,
          name: null,
          gender: null,
          birth_place: null,
          birth_date: null,
          phone_number: null,
          job: null,
          email: null,
          address: null,
          education_level_id: null,
          education_level: {
            name: null
          },
          position: null,
          company: null,
          civil_status_id: null,
          civil_status: {
            name: null
          },
          life_status: 'Hidup',
          medical_history: null,
          applicant_id: null,
          relation_type_id: null,
          relation_type: {
            id: null,
            name: null,
            status: null
          }
        },
        data_relation: {
          father: {
            index: null,
            data: null
          },
          mother: {
            index: null,
            data: null
          },
          sibling: {
            index: null,
            data: null
          },
          siblings: []
        },
        data_relation_default: {
          father: {
            index: null,
            data: null
          },
          mother: {
            index: null,
            data: null
          },
          sibling: {
            index: null,
            data: null
          },
          siblings: []
				},
				minDate: new Date(today.getFullYear() - 110, today.getMonth(), today.getDate()),
				maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate())
			}
		},
		mounted() {
			this.refresh()
			console.log(this.applicant)
		},
		computed: {
			...mapGetters({
				applicant: "applicant",
				stepper_position: "stepper_position"
			}),
			formTitle() {
				return this.edited_index === -1 
					? 'Tambah Data Keluarga' 
					: 'Ubah Data Keluarga'
      },
      filterCivilStatuses() {
        if (this.active_relation.relation_type_id === 1) {
          return this.civil_statuses.filter(item => {
            return item.id === 1 || item.id === 4
          })
        } else if (this.active_relation.relation_type_id === 2) {
          return this.civil_statuses.filter(item => {
            return item.id === 1 || item.id === 3
          })
        } else return this.civil_statuses
      }
		},
		methods: {
			...mapActions({
				setApplicant: 'setApplicant',
				setStepperPosition: 'setStepperPosition'
			}),

			refresh() {
        this.data_relation = this.data_relation_default
        this.isLoading = true
        this.axios.get('applicants/' + this.applicant.id + `/relations`)
        .then(response => {
          this.relations = response.data.data.filter(function (object) {
            return parseInt(object.relation_type.status) === 1;
          })
          for (let i = 0; i < this.relations.length; i++) {
            if (this.relations[i].relation_type_id == 1) {
              this.data_relation.father.data = this.relations[i].name
              this.data_relation.father.index = i
            } else if (this.relations[i].relation_type_id == 2) {
              this.data_relation.mother.data = this.relations[i].name
              this.data_relation.mother.index = i
            } else if (this.relations[i].relation_type_id == 3) {
              this.data_relation.sibling.data = this.relations[i].name
              this.data_relation.sibling.index = i
              this.data_relation.siblings.push({
                data: this.relations[i].name,
                index: i
              })
            }
          }
        })
        .catch(e => {
          // this.message = "Error: " + e.response.data.message
					// this.showSnackbar({ text: this.message })
					console.log(e)
        })
        .finally(() => {
          this.isLoading = false
        })
        if (!this.relation_types.length) {
          this.isLoading = true
          this.$axios.get('relation-types')
          .then(response => {
            this.relation_types = response.data.data.filter(function (object) {
              return parseInt(object.status) === 1;
            })
          })
          .catch(e => {
            this.message = "Error: " + e.response.data.message
            this.$buefy.snackbar.open({
							message: this.message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
          })
          .finally(() => {
            this.isLoading = false
          })
        }
        if (!this.civil_statuses.length) {
          this.isLoading = true
          this.$axios.get('civil-statuses')
          .then(response => {
            this.civil_statuses = response.data.data
          })
          .catch(e => {
            this.message = "Error: " + e.response.data.message
            this.$buefy.snackbar.open({
							message: this.message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
          })
          .finally(() => {
            this.isLoading = false
          })
        }
        if (!this.education_levels.length) {
          this.isLoading = true
          this.$axios.get('education-levels')
          .then(response => {
            this.education_levels = response.data.data
          })
          .catch(e => {
            this.message = "Error: " + e.response.data.message
            this.$buefy.snackbar.open({
							message: this.message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
          })
          .finally(() => {
            this.isLoading = false
          })
        }
      },
			async add() {
				this.isLoading = true
				await this.$axios.get(`applicants/${this.applicant.id}`)
					.then((response) => {
						var applicant = response.data.data;
						this.setApplicant(applicant);
						this.active_relation = Object.assign({}, this.active_relation_default)
						this.isCardModalActive = true
						this.isLoading = false
					})
					.catch((e) => {
						this.message = "Error: " + e.response.data.message;
						this.$buefy.snackbar.open({
							message: this.message,
							type: "is-info",
							position: "is-top",
							actionText: "Tutup",
							indefinite: false,
						});
						this.isLoading = false
					})
			},
			closeModal() {
				this.isCardModalActive = false
				this.active_relation = Object.assign({}, this.active_relation_default)
				this.edited_index = -1
        this.isLoading = false
			},
			updateRelationName() {
        for (var i = 0; i < this.relation_types.length; i++)  {
          if (this.relation_types[i].id === this.active_relation.relation_type_id) {
            this.active_relation.relation_type.name = this.relation_types[i].name
            break;
          }
        }
			},
			saveRelation(e) {
				this.active_relation.name = this.active_relation.name ? this.active_relation.name.replace(/^\s*/, "") : null
				this.active_relation.birth_place = this.active_relation.birth_place ? this.active_relation.birth_place.replace(/^\s*/, "") : null
				this.active_relation.company = this.active_relation.company ? this.active_relation.company.replace(/^\s*/, "") : null
				this.active_relation.company = this.active_relation.company != null ? this.active_relation.company.length == 1 && (this.active_relation.company == 0 || this.active_relation.company == "0") ? "" : this.active_relation.company : null
this.active_relation.company = this.active_relation.company != null ? this.active_relation.company.length > 1 && this.active_relation.company.trim().length === 1 && (this.active_relation.company.charAt(0) == 0 || this.active_relation.company.charAt(0) == "0") ? "" : this.active_relation.company : null
				this.active_relation.position = this.active_relation.position ? this.active_relation.position.replace(/^\s*/, "") : null
				this.active_relation.position = this.active_relation.position != null ? this.active_relation.position.length == 1 && (this.active_relation.position == 0 || this.active_relation.position == "0") ? "" : this.active_relation.position  : null
this.active_relation.position = this.active_relation.position != null ? this.active_relation.position.length > 1 && this.active_relation.position.trim().length === 1 && (this.active_relation.position.charAt(0) == 0 || this.active_relation.position.charAt(0) == "0") ? "" : this.active_relation.position : null

				this.active_relation.birth_date = this.active_relation.temp_birth_date == null 
					? null
					: dateFormat(this.active_relation.temp_birth_date)
				if (this.$refs.form.checkValidity() && this.active_relation.birth_date != null) {
					if (!(/^[A-Za-z\s]+$/i.test(this.active_relation.name)) || !(/^[A-Za-z\s]+$/i.test(this.active_relation.birth_place))) {
						this.$buefy.snackbar.open({
							message: "Gagal! Nama dan kota kelahiran hanya bisa alfabet dan spasi",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
						return false;
					}
					if ((this.active_relation.company && !(/^[A-Za-z0-9\s]+$/i.test(this.active_relation.company))) || !(/^[A-Za-z0-9\s]+$/i.test(this.active_relation.position))) {
						this.$buefy.snackbar.open({
							message: "Gagal! Perusahaan dan pekerjaan hanya bisa alfabet, angka dan spasi",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
						return false;
					}
					if (this.active_relation.phone_number && !(/^[0-9]+$/i.test(this.active_relation.phone_number))) {
						this.$buefy.snackbar.open({
							message: "Gagal! Nomor handphone hanya bisa angka",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
						return false;
					}

					this.isLoading = true
					this.message = null
					
					if (this.edited_index > -1) {
						this.$axios.patch(`applicants/` + this.applicant.id + `/relations/` + this.active_relation.id, this.active_relation
            )
            .then(response => {
              this.message = response.data.message
              if (response.data.status) {
								Object.assign(this.relations[this.edited_index], this.active_relation)
                this.closeModal()
                this.refresh()
              }
            })
            .catch(e => {
				if (e.response.data.status == "validation") {
						this.message = e.response.data.message
					} else {
						this.message = "Error: " + e.response.data.message
					}
            })
            .finally(() => {
              this.isLoading = false
              this.$buefy.snackbar.open({
								message: this.message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
            })
					} else {
						this.$axios.post(`applicants/` + this.applicant.id + `/relations`, this.active_relation
            )
            .then(response => {
              this.message = response.data.message
              if (response.data.status) {
								this.active_relation.id = response.data.data.id
								this.relations.push(this.active_relation)
                this.closeModal()
                this.refresh()
              }
            })
            .catch(e => {
              if (e.response.data.status == "validation") {
						this.message = e.response.data.message
					} else {
						this.message = "Error: " + e.response.data.message
					}
            })
            .finally(() => {
              this.isLoading = false
              this.$buefy.snackbar.open({
								message: this.message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
            })
          }  
				} else {
          this.message = "Gagal! Data kurang/tidak valid, silahkan cek kembali"
          this.$buefy.snackbar.open({
						message: this.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
        }
				e.preventDefault()
			},
			async editRelation(relation) {
				this.isLoading = true
				await this.$axios.get(`applicants/${this.applicant.id}`)
					.then((response) => {
						var applicant = response.data.data;
						this.setApplicant(applicant);

						this.edited_index = this.relations.indexOf(relation)
						this.active_relation = Object.assign({}, relation)
						this.active_relation.temp_birth_date = this.active_relation.birth_date == null 
							? null 
							: new Date(this.active_relation.birth_date)
						this.isCardModalActive = true
						this.isLoading = false
					})
					.catch((e) => {
						this.message = "Error: " + e.response.data.message;
						this.$buefy.snackbar.open({
							message: this.message,
							type: "is-info",
							position: "is-top",
							actionText: "Tutup",
							indefinite: false,
						});
						this.isLoading = false
					})
      },
      next() {
        if (this.relations.length > 0) {
			if (this.applicant.civil_status_id == 1) {
				let canContinue = false;
				for (let i = 0; i < this.relations.length; i++) {
					if (this.relations[i].relation_type_id == 4 && this.applicant.gender == 'Female') {
						canContinue = true
						break
					} else if (this.relations[i].relation_type_id == 5 && this.applicant.gender == 'Male') {
						canContinue = true
						break
					}
				}
				if (canContinue) {
					this.setStepperPosition(6)
				} else {
					let spouse = this.applicant.gender == 'Female' ? 'suami' : 'istri'
					this.$buefy.snackbar.open({
						message: 'Data ' + spouse +  ' wajib diisi terlebih dahulu!',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				}
			} else {
				this.setStepperPosition(6)
			}
        } else {
          this.$buefy.snackbar.open({
            message: 'Data keluarga inti masih kosong, silahkan isi sebelum melanjutkan',
            type: 'is-info',
            position: 'is-top',
            actionText: 'Tutup',
            indefinite: false,
          })
        }
      },
      saveBirthDate (date) {
        this.active_relation.birth_date = dateFormat(date)
      },
      prev() {
        this.setStepperPosition(4)
      },
			onlyNumber($event) {
				let keyCode = $event.keyCode ? $event.keyCode : $event.which;
				if (keyCode < 48 || keyCode > 57) {
					$event.preventDefault();
				}
			},
			onlyAlphabet($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[A-Za-z\s]+$/i.test(char)) { //check if alphabet
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			},
			onlyAlphaNum($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[A-Za-z0-9\s]+$/i.test(char)) {
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			},
		},
	}
</script>