<template>
	<section>
		<b-modal 
			v-model="isCardModalActive"
			width="auto"
			scroll="keep"
			has-modal-card
			trap-focus
		>
			<form ref="form">
				<div class="modal-card" style="width: auto">
					<header class="modal-card-head">
						<p class="title is-size-5-desktop is-size-6-mobile">{{ formTitle }}</p>
					</header>
					<section class="modal-card-body">
						<div class="columns is-desktop">
							<div class="column">
								<b-field label="Hubungan*">
									<b-select  
										v-model="active_relation.relation_type_id" 
										@input="updateRelationName"
										placeholder="Hubungan" 
										expanded
										validation-message="Silahkan isi bagian ini"
										required
									>
										<option 
											v-for="relation in relation_type"
											:key="relation.id"
											:value="relation.id"
										>{{ relation.name }}</option>
									</b-select>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Nama Lengkap*">
									<b-input
										v-model="active_relation.name"
										placeholder="Nama Lengkap"
										validation-message="Silahkan isi bagian ini"
										:maxlength="maxInputReferenceName"
										@keypress.native="onlyAlphabet"
										required
									></b-input>
								</b-field>
							</div>
						</div>
						<div class="columns is-desktop">
							<div class="column">
								<b-field label="Nomor Handphone*">
									<b-input
										v-model="active_relation.phone_number"
										placeholder="Nomor Handphone (Min. 10 Angka)"
										validation-message="Silahkan isi bagian ini"
										@keypress.native="onlyNumber"
										maxlength="13"
										minlength="10"
										required
									></b-input>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Jenis Kelamin*">
									<div class="block">
										<b-radio
											v-model="active_relation.gender"
											type="is-info"
											native-value="L"
											required
											name="gender">
											Laki-Laki
										</b-radio>
										<b-radio
											v-model="active_relation.gender"
											type="is-info"
											native-value="P"
											required
											name="gender">
											Perempuan
										</b-radio>
									</div>
								</b-field>
							</div>
						</div>
						<div class="columns is-desktop">
							<div class="column">
								<b-field label="Tempat/Perusahaan Bekerja*">
									<b-input
										v-model="active_relation.company"
										placeholder="Tempat/Perusahaan Bekerja"
										validation-message="Silahkan isi bagian ini"
										:maxlength="maxInputReferenceWorkplace"
										@keypress.native="onlyAlphaNum"
										required
									></b-input>
								</b-field>
							</div>
							<div class="column">
								<b-field label="Posisi/Jabatan/Pekerjaan*">
									<b-input
										v-model="active_relation.position"
										placeholder="Posisi/Jabatan/Pekerjaan"
										validation-message="Silahkan isi bagian ini"
										:maxlength="maxInputReferenceJob"
										@keypress.native="onlyAlphaNum"
										required
									></b-input>
								</b-field>
							</div>
						</div>
						<b-field label="Alamat">
							<b-input
								v-model="active_relation.address"
								placeholder="Alamat"
								:maxlength="maxInputReferenceAddress"
								@keypress.native="onlyAlphaNumAddress"
							></b-input>
						</b-field>
					</section>
					<footer class="modal-card-foot is-pulled-right">
						<b-button 
							class="is-pulled-right"  
							@click="closeModal()"
						>Kembali</b-button>
						<b-button 
							class="is-pulled-right"
							type="is-info" 
							@click.prevent="saveRelation"
              :loading="isLoading"
						>Simpan</b-button>
					</footer>
				</div>
			</form>
		</b-modal>
		<b-field>
			<b-button
				class="is-text"
				icon-left="chevron-left"
				@click="prev()"
			>
				Sebelumnya
			</b-button>
		</b-field>
		<div class="columns">
			<div class="column">
				<h4 class="title is-size-5-desktop is-size-6-mobile is-pulled-left"> 
					8 Referensi
				</h4>
			</div>
		</div>
		<div class="columns">
			<div class="column">
				<b-notification					
					type="is-danger is-light"
					aria-close-label="Close notification"
					role="alert"
					:closable="false">
					<p class="is-size-7-mobile">Ketentuan pengisian form:</p> 
					<p class="is-size-7-mobile has-icon-left has-text-weight-semibold">
						<b-icon 
							icon="check"
							size="is-small"
						></b-icon> 
						Referensi Wajib Diisi - Minimal 1
					</p>
				</b-notification>
			</div>
		</div>
		<div class="columns is-desktop">
			<div class="column is-one-quarter is-offset-9">
				<b-field>
					<b-button  
						class="button is-success is-pulled-right" 
						@click="add()" 
						:loading="isLoading"
						expanded
					>
						Tambah Data
					</b-button>
				</b-field>
			</div>
		</div>
		<b-table 
			class="box mt-6"
			:data="relations.length == 0 ? empty : relations"
			:loading="isLoading" 
		>
			<b-table-column
					label="Data Referensi"
					:visible="relations.length == 0"
					v-slot="props" 
				>
					{{ props.row }}
				</b-table-column>
				<b-table-column
					label="Nama"
					:visible="relations.length != 0"
					v-slot="props" 
				>
					{{ 
						props.row.name + " (" + 
						props.row.relation_type.name + ")"  
					}}
				</b-table-column>
				<b-table-column
					:visible="relations.length != 0"
					field="action"
					label="Aksi"
					v-slot="props" 
					centered
				>
					<b-button  
						class="button is-warning is-light" 
						@click="editRelation(props.row)"
					>Ubah</b-button>
			</b-table-column>
		</b-table>
		<b-field>
			<b-button  
				class="button is-info" 
				@click="next" 
				expanded
				:loading="isLoading"
			>
				Simpan & Lanjut
			</b-button>
		</b-field>
	</section>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex'
	export default {
		data() {
			return {
				isLoading: false,
				isCardModalActive: false,
				edited_index: -1,
				maxInputReferenceAddress: 40,
				maxInputReferenceName: 50,
				maxInputReferenceWorkplace: 50,
				maxInputReferenceJob: 50,

				empty: ['Belum ada data'],
				
				relation_type: [],
				relations: [],
				active_relation: {
					id: null,
					name: null,
					gender: null,
					birth_place: null,
					birth_date: null,
					phone_number: null,
					job: null,
					email: null,
					address: null,
					last_education: null,
					position: null,
					company: null,
					civil_status: null,
					life_status: "Hidup",
					medical_history: null,
					applicant_id: null,
					relation_type: {
						id: null,
						name: null,
						status: null
					}
				},
				active_relation_default: {
					id: null,
					name: null,
					gender: null,
					birth_place: null,
					birth_date: null,
					phone_number: null,
					job: null,
					email: null,
					address: null,
					last_education: null,
					position: null,
					company: null,
					civil_status: null,
					life_status: "Hidup",
					medical_history: null,
					applicant_id: null,
					relation_type: {
						id: null,
						name: null,
						status: null
					}
				}
			}
		},
		async mounted() {
			await this.refresh()
		},
		computed: {
			...mapGetters({
				applicant: "applicant",
				stepper_position: "stepper_position"
			}),
			formTitle() {
				return this.edited_index === -1
					? "Tambah Data Referensi"
					: "Ubah Data Referensi";
			}
		},
		methods: {
			...mapActions({
				setApplicant: "setApplicant",
				setStepperPosition: "setStepperPosition"
			}),
			async next() {
				await this.refresh()
				const foundReference = this.relations.some(item => item.length !== 0 || item.relation_type.code == "OtherContact")
				if (!foundReference) {
					this.$buefy.snackbar.open({
						message: 'Data referensi belum terisi, silahkan mengisi terlebih dahulu',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				} 
				else {
					this.setStepperPosition(8)
				}
			},
			add() {
				this.isCardModalActive = true;
				this.active_relation = Object.assign({},this.active_relation_default);
				this.edited_index = -1
			},
			refresh() {
				this.isLoading = true;
				this.$axios
					.get("applicants/" + this.applicant.id + `/relations`)
					.then(response => {
						this.relations = response.data.data.filter(function(object) {
							return parseInt(object.relation_type.status) === 3;
						});
					})
					.catch(e => {
						this.message = "Error: " + e.response.data.message;
						this.$buefy.snackbar.open({
							message: this.message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					})
					.finally(() => {
						this.isLoading = false;
					});
				if (!this.relation_type.length) {
					this.isLoading = true;
					this.$axios
						.get("relation-types")
						.then(response => {
							this.relation_type = response.data.data.filter(function(object) {
								return parseInt(object.status) === 3;
							});
						})
						.catch(e => {
							this.message = "Error: " + e.response.data.message;
							this.$buefy.snackbar.open({
								message: this.message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
						})
						.finally(() => {
							this.isLoading = false;
						});
				}
			},
			updateRelationName() {
				for (var i = 0; i < this.relation_type.length; i++) {
					if (
						this.relation_type[i].id === this.active_relation.relation_type_id
					) {
						this.active_relation.relation_type.name = this.relation_type[i].name;
						break;
					}
				}
			},
			closeModal() {
				this.isCardModalActive = false;
				this.active_relation = Object.assign({},this.active_relation_default);
				this.edited_index = -1;
				this.isLoading = false;
			},
			saveRelation(e) {
				// delete leading spaces
				this.active_relation.name = this.active_relation.name ? this.active_relation.name.replace(/^\s*/, "") : null
				this.active_relation.company = this.active_relation.company ? this.active_relation.company.replace(/^\s*/, "") : null
				this.active_relation.company = this.active_relation.company != null ? this.active_relation.company.length == 1 && (this.active_relation.company == 0 || this.active_relation.company == "0") ? "" : this.active_relation.company : null
this.active_relation.company = this.active_relation.company != null ? this.active_relation.company.length > 1 && this.active_relation.company.trim().length === 1 && (this.active_relation.company.charAt(0) == 0 || this.active_relation.company.charAt(0) == "0") ? "" : this.active_relation.company : null
				this.active_relation.position = this.active_relation.position ? this.active_relation.position.replace(/^\s*/, "") : null
				this.active_relation.position = this.active_relation.position != null ? this.active_relation.position.length == 1 && (this.active_relation.position == 0 || this.active_relation.position == "0") ? "" : this.active_relation.position : null
this.active_relation.position = this.active_relation.position != null ? this.active_relation.position.length > 1 && this.active_relation.position.trim().length === 1 && (this.active_relation.position.charAt(0) == 0 || this.active_relation.position.charAt(0) == "0") ? "" : this.active_relation.position : null
				this.active_relation.address = this.active_relation.address ? this.active_relation.address.replace(/^\s*/, "") : null
				this.active_relation.address = this.active_relation.address != null ? this.active_relation.address.length == 1 && (this.active_relation.address == 0 || this.active_relation.address == "0") ? "" : this.active_relation.address : null
this.active_relation.address = this.active_relation.address != null ? this.active_relation.address.length > 1 && this.active_relation.address.trim().length === 1 && (this.active_relation.address.charAt(0) == 0 || this.active_relation.address.charAt(0) == "0") ? "" : this.active_relation.address : null

				if (this.$refs.form.checkValidity()) {
					if (!(/^[A-Za-z\s]+$/i.test(this.active_relation.name))) {
						this.$buefy.snackbar.open({
							message: "Gagal! Nama hanya bisa alfabet dan spasi",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
						return false;
					}
					if (!(/^[0-9]+$/i.test(this.active_relation.phone_number))) {
						this.$buefy.snackbar.open({
							message: "Gagal! Nomor handphone hanya bisa angka",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
						return false;
					}
					if (!(/^[A-Za-z0-9\s]+$/i.test(this.active_relation.company)) || !(/^[A-Za-z0-9\s]+$/i.test(this.active_relation.position))) {
						this.$buefy.snackbar.open({
							message: "Gagal! Perusahaan, dan posisi / pekerjaan hanya bisa alfabet, angka dan spasi",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
						return false;
					}
					if (this.active_relation.address && !(/^[A-Za-z0-9\s.,]+$/i.test(this.active_relation.address))) {
						this.$buefy.snackbar.open({
							message: "Gagal! Alamat hanya bisa alfabet, angka, tanda titik, koma, dan spasi",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
						return false;
					}

					this.isLoading = true;
					this.message = null;
					if (this.edited_index > -1) {
						this.$axios
							.patch(
								`applicants/` +
									this.applicant.id +
									`/relations/` +
									this.active_relation.id,
								this.active_relation
							)
							.then(response => {
								this.message = response.data.message;
								if (response.data.status) {
									Object.assign(
										this.relations[this.edited_index],
										this.active_relation
									);
									this.closeModal();
								}
							})
							.catch(e => {
								this.message = "Error: " + e.response.data.message;
							})
							.finally(() => {
								this.isLoading = false;
								this.$buefy.snackbar.open({
									message: this.message,
									type: 'is-info',
									position: 'is-top',
									actionText: 'Tutup',
									indefinite: false,
								})
							});
					} else {
						this.$axios
							.post(
								`applicants/` + this.applicant.id + `/relations`,
								this.active_relation
							)
							.then(response => {
								this.message = response.data.message;
								if (response.data.status) {
									this.active_relation.id = response.data.data.id;
									this.relations.push(this.active_relation);
									this.closeModal();
								}
							})
							.catch(e => {
								this.message = "Error: " + e.response.data.message;
							})
							.finally(() => {
								this.isLoading = false;
								this.$buefy.snackbar.open({
									message: this.message,
									type: 'is-info',
									position: 'is-top',
									actionText: 'Tutup',
									indefinite: false,
								})
							});
					}
				} else {
					this.$buefy.snackbar.open({
						message: 'Gagal! Data kurang/tidak valid, silahkan cek kembali',
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				}
				e.preventDefault();
			},
			editRelation(relation) {
				this.edited_index = this.relations.indexOf(relation);
				this.active_relation = Object.assign({}, relation);
				this.isCardModalActive = true;
			},
			prev() {
				this.setStepperPosition(6)
			},
			onlyNumber($event) {
				let keyCode = $event.keyCode ? $event.keyCode : $event.which;
				if (keyCode < 48 || keyCode > 57) {
					// 46 is dot
					$event.preventDefault();
				}
			},
			onlyAlphabet($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[A-Za-z\s]+$/i.test(char)) { //check if alphabet
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			},
			onlyAlphaNumAddress($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[A-Za-z0-9\s.,]+$/i.test(char)) { //including dot and coma
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			},
			onlyAlphaNum($event) {
				let char = String.fromCharCode($event.keyCode);
				if (/^[A-Za-z0-9\s]+$/i.test(char)) { //including dot and coma
					return true;
				} else {
					$event.preventDefault();
					return false;
				}
			}
		},
	}
</script>