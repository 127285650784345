<template>
  <section>
    <b-field>
      <b-button class="is-text" icon-left="chevron-left" @click="prev()">
        Sebelumnya
      </b-button>
    </b-field>
    <div class="columns">
      <div class="column">
        <h4 class="title is-size-5-desktop is-size-6-mobile is-pulled-left">
          5 Alamat
        </h4>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-notification
          type="is-danger is-light"
          aria-close-label="Close notification"
          role="alert"
          :closable="false"
        >
          <p class="is-size-7-mobile">Ketentuan pengisian form:</p>
          <p class="is-size-7-mobile has-icon-left has-text-weight-semibold">
            <b-icon icon="check" size="is-small"></b-icon>
            Alamat Harus Sesuai KTP
          </p>
          <p class="is-size-7-mobile has-icon-left has-text-weight-semibold">
            <b-icon icon="check" size="is-small"></b-icon>
            Alamat Wajib Diisi
          </p>
        </b-notification>
      </div>
    </div>
    <div class="columns is-mobile">
      <div class="column is-one-quarter is-offset-9">
        <b-field>
          <b-button class="button is-info is-pulled-right" @click="refresh()">
            Perbarui
          </b-button>
        </b-field>
      </div>
    </div>

    <b-tabs v-model="tabs">
      <b-tab-item label="Alamat KTP">
        <form ref="formKtp">
          <div class="columns is-desktop">
            <div class="column">
              <b-field label="Provinsi*">
                <b-select
                  placeholder="Provinsi"
                  v-model="identity_address.state_id"
                  @input="updateCounty('identity')"
                  validation-message="Silahkan isi sesuai data KTP Anda"
                  expanded
                  required
                >
                  <option
                    v-for="province in states"
                    :key="province.STATEID"
                    :value="province.STATEID"
                  >
                    {{ province.NAME }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Kota / Kabupaten*">
                <b-select
                  v-model="identity_address.county_id"
                  @input="updateCity('identity')"
                  placeholder="Kota / Kabupaten"
                  validation-message="Silahkan isi sesuai data KTP Anda"
                  expanded
                  required
                >
                  <option
                    v-for="county in identity_counties"
                    :key="county.COUNTYRECID"
                    :value="county.COUNTYRECID"
                  >
                    {{ county.NAME }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Kecamatan*">
                <b-select
                  placeholder="Kecamatan"
                  v-model="identity_address.city_id"
                  @input="updateDistrict('identity')"
                  validation-message="Silahkan isi sesuai data KTP Anda"
                  expanded
                  required
                >
                  <option
                    v-for="city in identity_cities"
                    :key="city.CITYRECID"
                    :value="city.CITYRECID"
                  >
                    {{ city.DESCRIPTION }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Kelurahan*">
                <b-select
                  v-model="identity_address.district_id"
                  @input="updateSubDistrict('identity')"
                  placeholder="Kelurahan"
                  validation-message="Silahkan isi sesuai data KTP Anda"
                  expanded
                  required
                >
                  <option
                    v-for="district in identity_districts"
                    :key="district.DISTRICTRECID"
                    :value="district.DISTRICTRECID"
                  >
                    {{ district.DESCRIPTION }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Kode Pos*">
                <b-input
                  placeholder="Kode Pos"
                  minlength="5"
                  maxlength="5"
                  v-model="identity_address.postal_code"
                  validation-message="Silahkan isi sesuai format dan data KTP Anda"
                  @keypress.native="onlyNumber"
                  required
                ></b-input>
              </b-field>
              <b-field label="Nomor Telepon Rumah">
                <b-input
                  @keypress.native="onlyNumber"
                  placeholder="Nomor Telepon Rumah (Min. 10 Angka)"
                  v-model="identity_address.phone_number"
                  minlength="10"
                  maxlength="13"
                  validation-message="Silahkan isi sesuai format dan data KTP Anda"
                ></b-input>
              </b-field>
              <b-field label="Kepemilikan*">
                <b-select
                  v-model="identity_address.owner"
                  placeholder="Kepemilikan"
                  expanded
                  required
                >
                  <option
                    v-for="owner in ownership"
                    :key="owner"
                    :value="owner"
                  >
                    {{ owner }}
                  </option>
                </b-select>
              </b-field>
              <div class="columns is-desktop">
                <div class="column">
                  <b-field label="RT*">
                    <b-input
                      placeholder="RT"
                      maxlength="3"
                      v-model="identity_address.neighbourhood"
                      @keypress.native="onlyNumber"
                      required
                      validation-message="Silahkan isi sesuai format dan data KTP Anda"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="RW*">
                    <b-input
                      placeholder="RW"
                      maxlength="3"
                      v-model="identity_address.hamlet"
                      @keypress.native="onlyNumber"
                      required
                      validation-message="Silahkan isi sesuai format dan data KTP Anda"
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <b-field label="Alamat*">
            <b-input
              placeholder="Alamat Lengkap (Jalan, Nomor Rumah)"
              v-model="identity_address.full_address"
              validation-message="Silahkan isi sesuai format dan data KTP Anda"
              :maxlength="maxInputAddress"
              @keypress.native="onlyAlphaNum"
              required
            ></b-input>
          </b-field>
          <b-field>
            <b-button class="button is-info mt-5" expanded @click="partTwo">
              Alamat Domisili
            </b-button>
          </b-field>
        </form>
      </b-tab-item>
      <b-tab-item
        :disabled="tabs_disabled"
        :value="'1'"
        label="Alamat Domisili"
      >
        <b-field>
          <b-checkbox
            type="is-info"
            v-model="same_address"
            @input="isSameAddress"
            >Alamat Domisili sama dengan Alamat di KTP
          </b-checkbox>
        </b-field>
        <form ref="formDomicile" v-show="!same_address">
          <div class="columns is-desktop">
            <div class="column">
              <b-field label="Provinsi*">
                <b-select
                  placeholder="Provinsi"
                  v-model="domicile_address.state_id"
                  @input="updateCounty('domicile')"
                  validation-message="Silahkan isi sesuai data Domisili Anda"
                  expanded
                  required
                >
                  <option
                    v-for="province in states"
                    :key="province.STATEID"
                    :value="province.STATEID"
                  >
                    {{ province.NAME }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Kota / Kabupaten*">
                <b-select
                  v-model="domicile_address.county_id"
                  @input="updateCity('domicile')"
                  placeholder="Kota / Kabupaten"
                  validation-message="Silahkan isi sesuai data Domisili Anda"
                  expanded
                  required
                >
                  <option
                    v-for="county in domicile_counties"
                    :key="county.COUNTYRECID"
                    :value="county.COUNTYRECID"
                  >
                    {{ county.NAME }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Kecamatan*">
                <b-select
                  placeholder="Kecamatan"
                  v-model="domicile_address.city_id"
                  @input="updateDistrict('domicile')"
                  validation-message="Silahkan isi sesuai data Domisili Anda"
                  expanded
                  required
                >
                  <option
                    v-for="city in domicile_cities"
                    :key="city.CITYRECID"
                    :value="city.CITYRECID"
                  >
                    {{ city.DESCRIPTION }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Kelurahan*">
                <b-select
                  v-model="domicile_address.district_id"
                  @input="updateSubDistrict('domicile')"
                  placeholder="Kelurahan"
                  validation-message="Silahkan isi sesuai data Domisili Anda"
                  expanded
                  required
                >
                  <option
                    v-for="district in domicile_districts"
                    :key="district.DISTRICTRECID"
                    :value="district.DISTRICTRECID"
                  >
                    {{ district.DESCRIPTION }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Kode Pos*">
                <b-input
                  placeholder="Kode Pos"
                  maxlength="5"
                  v-model="domicile_address.postal_code"
                  validation-message="Silahkan isi sesuai format dan data Domisili Anda"
                  @keypress.native="onlyNumber"
                  required
                ></b-input>
              </b-field>
              <b-field label="Nomor Telepon Rumah">
                <b-input
                  @keypress.native="onlyNumber"
                  placeholder="Nomor Telepon Rumah (Min. 10 Angka)"
                  v-model="domicile_address.phone_number"
                  minlength="10"
                  maxlength="13"
                  validation-message="Silahkan isi sesuai format dan data Domisili Anda"
                ></b-input>
              </b-field>
              <b-field label="Kepemilikan">
                <b-select
                  v-model="domicile_address.owner"
                  placeholder="Kepemilikan"
                  expanded
                  required
                >
                  <option v-for="owner in ownership" :key="owner">
                    {{ owner }}
                  </option>
                </b-select>
              </b-field>
              <div class="columns is-desktop">
                <div class="column">
                  <b-field label="RT*">
                    <b-input
                      placeholder="RT"
                      maxlength="3"
                      v-model="domicile_address.neighbourhood"
                      @keypress.native="onlyNumber"
                      required
                      validation-message="Silahkan isi sesuai format dan data Domisili Anda"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="RW*">
                    <b-input
                      placeholder="RW"
                      maxlength="3"
                      v-model="domicile_address.hamlet"
                      @keypress.native="onlyNumber"
                      required
                      validation-message="Silahkan isi sesuai format dan data Domisili Anda"
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <b-field label="Alamat*">
            <b-input
              placeholder="Alamat Lengkap (Jalan, Nomor Rumah)"
              v-model="domicile_address.full_address"
              :maxlength="maxInputAddress"
              @keypress.native="onlyAlphaNum"
              required
              validation-message="Silahkan isi sesuai format dan data Domisili Anda"
            ></b-input>
          </b-field>
        </form>
        <b-field>
          <b-button
            class="button is-info mt-5"
            expanded
            :loading="isLoading"
            @click="save"
          >
            Simpan & Lanjut
          </b-button>
        </b-field>
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      maxInputAddress: 100,
      indonesia_rec_id: 5637148630,

      in_indonesia: true,

      same_address: false,

      tabs: undefined,
      tabs_disabled: true,

      ownership: ["Sendiri", "Sewa/Kontrak", "Indekost", "Orang Tua/Keluarga"],

      // Master
      countries: [],
      states: [],
      counties: [],
      cities: [],
      districts: [],

      identity_counties: [],
      identity_cities: [],
      identity_districts: [],

      domicile_counties: [],
      domicile_cities: [],
      domicile_districts: [],

      address_types: ["KTP", "Domisili", "KTP dan Domisili"],

      // Dialogs
      isLoading: false,
      isCardModalActive: false,
      edited_index: -1,

      // Formal address Table
      addresses: [],

      identity_address: {
        id: null,
        full_address: null,
        neighbourhood: null,
        hamlet: null,
        postal_code: null,
        owner: null,
        phone_number: null,
        country_id: 5637148630,
        country_name: "INDONESIA",
        state_id: null,
        state_recid: null,
        state_name: null,
        county_id: null,
        county_code: null,
        county_name: null,
        city_id: null,
        city_name: null,
        district_id: null,
        district_name: null,
        type: "KTP",
      },

      domicile_address: {
        id: null,
        full_address: null,
        neighbourhood: null,
        hamlet: null,
        postal_code: null,
        owner: null,
        phone_number: null,
        country_id: 5637148630,
        country_name: "INDONESIA",
        state_id: null,
        state_recid: null,
        state_name: null,
        county_id: null,
        county_code: null,
        county_name: null,
        city_id: null,
        city_name: null,
        district_id: null,
        district_name: null,
        type: "Domisili",
      },
    };
  },
  async mounted() {
    await this.updateState();
    await this.getAddresses();
  },
  computed: {
    ...mapGetters({
      applicant: "applicant",
      stepper_position: "stepper_position",
    }),
  },
  methods: {
    ...mapActions({
      setApplicant: "setApplicant",
      setStepperPosition: "setStepperPosition",
    }),
    async getAddresses() {
      try {
        const res = await this.$axios.get(
          `applicants/${this.applicant.id}/addresses`
        );
        this.addresses = res.data.data;

        if (this.addresses.length > 0) {
          this.identity_address = await this.addresses.find(
            ({ ...item }) => item.type === "KTP"
          );
          this.domicile_address = await this.addresses.find(
            ({ ...item }) => item.type === "Domisili"
          );

          if (
            this.identity_address.full_address ===
            this.domicile_address.full_address
          ) {
            this.same_address = true;
          } else {
            this.same_address = false;
          }

          await this.getAllAddress();
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);

        let message = `Error: ${error.response.data.message}`;
        this.$buefy.snackbar.open({
          message: message,
          type: "is-info",
          position: "is-top",
          actionText: "Tutup",
        });
        this.isLoading = false;
      }
    },

    async getAllAddress() {
      let getIdentityState = null
      let getDomicileState = null
      if (!this.identity_address.state_recid) {
        getIdentityState = this.states.filter(
          (item) => item.STATEID == this.identity_address.state_id
        )
        this.identity_address.state_name = getIdentityState[0].NAME
        this.identity_address.state_recid = getIdentityState[0].STATERECID
      }
      if (!this.domicile_address.state_recid) {
        getDomicileState = this.states.filter(
          (item) => item.STATEID == this.domicile_address.state_id
        )
        this.domicile_address.state_name = getDomicileState[0].NAME
        this.domicile_address.state_recid = getDomicileState[0].STATERECID
      }
      await this.getCounties("identity", this.identity_address.state_recid);
      await this.getCities(
        "identity",
        this.identity_address.county_id,
        this.identity_address.state_recid
      );
      await this.getDistricts("identity", this.identity_address.city_id, this.identity_address.state_recid);
      await this.getIdentitySubDistrict();

      await this.getCounties("domicile", this.domicile_address.state_recid);
      await this.getCities(
        "domicile",
        this.domicile_address.county_id,
        this.domicile_address.state_recid
      );
      await this.getDistricts("domicile", this.domicile_address.city_id, this.domicile_address.state_recid);
      await this.getDomicileSubDistrict();
    },

    getIdentityCounty(type) {
      const tempIdentityState = this.states.find(
        (item) => item.STATEID === this.identity_address.state_id
      );
      this.identity_address.state_name = tempIdentityState.NAME;
      this.identity_address.state_recid = tempIdentityState.STATERECID;

      this.resetCounty(type);
      this.getCounties(type, this.identity_address.state_recid);
    },

    getDomicileCounty(type) {
      const tempDomicileState = this.states.find(
        (item) => item.STATEID === this.domicile_address.state_id
      );
      this.domicile_address.state_name = tempDomicileState.NAME;
      this.domicile_address.state_recid = tempDomicileState.STATERECID;

      this.resetCounty(type);
      this.getCounties(type, this.domicile_address.state_recid);
    },

    getBothCounty(type) {
      this.getIdentityCounty(type);
      if (this.same_address != true) this.getDomicileCounty(type);
    },

    getIdentityCity(type) {
      const tempCity = this.identity_counties.find(
        (item) => item.COUNTYRECID === this.identity_address.county_id
      );

      this.identity_address.county_name = tempCity.NAME;
      //this.identity_address.county_code = tempCity.COUNTYID;

      this.resetCity(type);
      this.getCities(
        type,
        this.identity_address.county_id,
        this.identity_address.state_recid
      );
    },

    getDomicileCity(type) {
      const tempCity = this.domicile_counties.find(
        (item) => item.COUNTYRECID === this.domicile_address.county_id
      );

      this.domicile_address.county_name = tempCity.NAME;
      //this.domicile_address.county_code = tempCity.COUNTYID;

      this.resetCity(type);
      this.getCities(
        type,
        this.domicile_address.county_id,
        this.domicile_address.state_recid
      );
    },

    getBothCity(type) {
      this.getIdentityCity(type);
      if (this.same_address != true) this.getDomicileCity(type);
    },

    getIdentityDistrict(type) {
      const tempDistrict = this.identity_cities.find(
        (item) => item.CITYRECID === this.identity_address.city_id
      );
      this.identity_address.city_name = tempDistrict.DESCRIPTION;

      this.resetDistrict(type);
      this.getDistricts(type, this.identity_address.city_id, this.identity_address.state_recid);
    },

    getDomicileDistrict(type) {
      const tempDistrict = this.domicile_cities.find(
        (item) => item.CITYRECID === this.domicile_address.city_id
      );
      this.domicile_address.city_name = tempDistrict.DESCRIPTION;

      this.resetDistrict(type);
      this.getDistricts(type, this.domicile_address.city_id, this.domicile_address.state_recid);
    },

    getBothDistrict(type) {
      this.getIdentityDistrict(type);

      if (this.same_address != true) this.getDomicileDistrict(type);
    },

    async save() {
      // delete leading spaces
      this.identity_address.full_address = this.identity_address.full_address ? this.identity_address.full_address.replace(/^\s*/, "") : null
      this.domicile_address.full_address = this.domicile_address.full_address ? this.domicile_address.full_address.replace(/^\s*/, "") : null
      this.identity_address.full_address = this.identity_address.full_address != null ? this.identity_address.full_address.length == 1 && (this.identity_address.full_address == 0 || this.identity_address.full_address == "0") ? "" : this.identity_address.full_address : null
      this.identity_address.full_address = this.identity_address.full_address != null ? this.identity_address.full_address.length > 1 && this.identity_address.full_address.trim().length === 1 && (this.identity_address.full_address.charAt(0) == 0 || this.identity_address.full_address.charAt(0) == "0") ? "" : this.identity_address.full_address : null
      this.domicile_address.full_address = this.domicile_address.full_address != null ? this.domicile_address.full_address.length == 1 && (this.domicile_address.full_address == 0 || this.domicile_address.full_address == "0") ? "" : this.domicile_address.full_address : null
      this.domicile_address.full_address = this.domicile_address.full_address != null ? this.domicile_address.full_address.length > 1 && this.domicile_address.full_address.trim().length === 1 && (this.domicile_address.full_address.charAt(0) == 0 || this.domicile_address.full_address.charAt(0) == "0") ? "" : this.domicile_address.full_address : null

      this.identity_address.neighbourhood = this.identity_address.neighbourhood != null ? this.identity_address.neighbourhood.length == 1 && (this.identity_address.neighbourhood == 0 || this.identity_address.neighbourhood == "0") ? "" : this.identity_address.neighbourhood : null
      this.identity_address.neighbourhood = this.identity_address.neighbourhood != null ? this.identity_address.neighbourhood.length > 1 && this.identity_address.neighbourhood.trim().length === 1 && (this.identity_address.neighbourhood.charAt(0) == 0 || this.identity_address.neighbourhood.charAt(0) == "0") ? "" : this.identity_address.neighbourhood : null
      this.identity_address.hamlet = this.identity_address.hamlet != null ? this.identity_address.hamlet.length == 1 && (this.identity_address.hamlet == 0 || this.identity_address.hamlet == "0") ? "" : this.identity_address.hamlet : null
      this.identity_address.hamlet = this.identity_address.hamlet != null ? this.identity_address.hamlet.length > 1 && this.identity_address.hamlet.trim().length === 1 && (this.identity_address.hamlet.charAt(0) == 0 || this.identity_address.hamlet.charAt(0) == "0") ? "" : this.identity_address.hamlet : null
      this.identity_address.postal_code = this.identity_address.postal_code != null ? this.identity_address.postal_code.length == 1 && (this.identity_address.postal_code == 0 || this.identity_address.postal_code == "0") ? "" : this.identity_address.postal_code : null
      this.identity_address.postal_code = this.identity_address.postal_code != null ? this.identity_address.postal_code.length > 1 && this.identity_address.postal_code.trim().length === 1 && (this.identity_address.postal_code.charAt(0) == 0 || this.identity_address.postal_code.charAt(0) == "0") ? "" : this.identity_address.postal_code : null

      this.domicile_address.neighbourhood = this.domicile_address.neighbourhood != null ? this.domicile_address.neighbourhood.length == 1 && (this.domicile_address.neighbourhood == 0 || this.domicile_address.neighbourhood == "0") ? "" : this.domicile_address.neighbourhood : null
      this.domicile_address.neighbourhood = this.domicile_address.neighbourhood != null ? this.domicile_address.neighbourhood.length > 1 && this.domicile_address.neighbourhood.trim().length === 1 && (this.domicile_address.neighbourhood.charAt(0) == 0 || this.domicile_address.neighbourhood.charAt(0) == "0") ? "" : this.domicile_address.neighbourhood : null
      this.domicile_address.hamlet = this.domicile_address.hamlet != null ? this.domicile_address.hamlet.length == 1 && (this.domicile_address.hamlet == 0 || this.domicile_address.hamlet == "0") ? "" : this.domicile_address.hamlet : null
      this.domicile_address.hamlet = this.domicile_address.hamlet != null ? this.domicile_address.hamlet.length > 1 && this.domicile_address.hamlet.trim().length === 1 && (this.domicile_address.hamlet.charAt(0) == 0 || this.domicile_address.hamlet.charAt(0) == "0") ? "" : this.domicile_address.hamlet : null
      this.domicile_address.postal_code = this.domicile_address.postal_code != null ? this.domicile_address.postal_code.length == 1 && (this.domicile_address.postal_code == 0 || this.domicile_address.postal_code == "0") ? "" : this.domicile_address.postal_code : null
      this.domicile_address.postal_code = this.domicile_address.postal_code != null ?this.domicile_address.postal_code.length > 1 && this.domicile_address.postal_code.trim().length === 1 && (this.domicile_address.postal_code.charAt(0) == 0 || this.domicile_address.postal_code.charAt(0) == "0") ? "" : this.domicile_address.postal_code : null

      if (!this.$refs.formKtp.checkValidity()) {
        this.$buefy.snackbar.open({
          message: "Gagal! Data alamat KTP kurang/tidak valid, silahkan cek kembali",
          type: "is-info",
          position: "is-top",
          actionText: "Tutup",
        });
      } else {
        if (
          this.same_address == false &&
          !this.$refs.formDomicile.checkValidity()
        ) {
          this.message = "Gagal! Data alamat domisili kurang/tidak valid, silahkan cek kembali";
          this.$buefy.snackbar.open({
            message: this.message,
            type: "is-info",
            position: "is-top",
            actionText: "Tutup",
          });
          return false;
        } else {
          if (!(/^[0-9]+$/i.test(this.identity_address.postal_code))) {
            this.$buefy.snackbar.open({
							message: "Gagal! Kode pos KTP hanya bisa angka",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
          }
          if ((this.identity_address.phone_number && !(/^[0-9]+$/i.test(this.identity_address.phone_number)))) {
            this.$buefy.snackbar.open({
							message: "Gagal! Nomor telepon KTP hanya bisa angka",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
          }
          if (!(/^[0-9]+$/i.test(this.identity_address.neighbourhood))) {
            this.$buefy.snackbar.open({
							message: "Gagal! RT KTP hanya bisa angka",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
          }
          if (!(/^[0-9]+$/i.test(this.identity_address.hamlet))) {
            this.$buefy.snackbar.open({
							message: "Gagal! RW KTP hanya bisa angka",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
          }
          if (!(/^[A-Za-z0-9\s.,]+$/i.test(this.identity_address.full_address))) {
            this.$buefy.snackbar.open({
							message: "Gagal! Alamat KTP hanya bisa angka, alfabet, tanda titik, koma, dan spasi",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
          }

          if (this.same_address == false && !(/^[0-9]+$/i.test(this.domicile_address.postal_code))) {
            this.$buefy.snackbar.open({
							message: "Gagal! Kode pos domisili hanya bisa angka",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
          }
          if ((this.same_address == false && this.domicile_address.phone_number && !(/^[0-9]+$/i.test(this.domicile_address.phone_number)))) {
            this.$buefy.snackbar.open({
							message: "Gagal! Nomor telepon domisili hanya bisa angka",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
          }
          if (this.same_address == false && !(/^[0-9]+$/i.test(this.domicile_address.neighbourhood))) {
            this.$buefy.snackbar.open({
							message: "Gagal! RT domisili hanya bisa angka",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
          }
          if (this.same_address == false && !(/^[0-9]+$/i.test(this.domicile_address.hamlet))) {
            this.$buefy.snackbar.open({
							message: "Gagal! RW domisili hanya bisa angka",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
          }
          if (this.same_address == false && !(/^[A-Za-z0-9\s.,]+$/i.test(this.domicile_address.full_address))) {
            this.$buefy.snackbar.open({
							message: "Gagal! Alamat domisili hanya bisa angka, alfabet, tanda titik, koma, dan spasi",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
          }

          this.isLoading = true;
          this.message = null;

          await this.$axios
            .post(`applicants/` + this.applicant.id + `/addresses/new`, {
              identity_address: this.identity_address,
              domicile_address: this.domicile_address,
              same_address: this.same_address,
            })
            .then((response) => {
              this.message = response.data.message;
              this.setStepperPosition(5);
            })
            .catch((e) => {
              this.message = "Error: " + e.response.data.message;
              return false;
            })
            .finally(() => {
              this.$buefy.snackbar.open({
                message: this.message,
                type: "is-info",
                position: "is-top",
                actionText: "Tutup",
              });
              this.isLoading = false;
              // this.$$refs.formDomicile.reset();
            });
        }
      }
    },
    async refresh() {
      this.isLoading = true;

      await this.updateState();
      await this.getAddresses();
    },

    async updateState() {
      await this.$axios
        .post(process.env.VUE_APP_API_URL + 'master-gateway', {
          code: 'M2',
        })
        .then((response) => {
          this.states = response.data;
        })
        .catch((e) => {
          this.message = "Error: " + e.response.data.message;
          this.$buefy.snackbar.open({
            message: this.message,
            type: "is-info",
            position: "is-top",
            actionText: "Tutup",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateCounty(type) {
      if (type == "identity") this.getIdentityCounty(type);
      else if (type == "domicile") this.getDomicileCounty(type);
      else this.getBothCounty(type);
    },
    async getCounties(type, state_recid) {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + `master-gateway`, {
            code: 'M3',
            STATERECID: state_recid
          }
        )
        .then((response) => {
          if (type == "identity") {
            this.identity_counties = response.data;
          } else if (type == "domicile") {
            this.domicile_counties = response.data;
          } else {
            this.counties = response.data;
          }
        })
        .catch((e) => {
          this.message = "Error: " + e.response.data.message;
          this.$buefy.snackbar.open({
            message: this.message,
            type: "is-info",
            position: "is-top",
            actionText: "Tutup",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateCity(type) {
      if (type == "identity") this.getIdentityCity(type);
      else if (type == "domicile") this.getDomicileCity(type);
      else this.getBothCity(type);
    },

    async getCities(type, county_id, state_recid) {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            `master-gateway`, {
              code: 'M4',
              COUNTYRECID: county_id,
              STATERECID: state_recid
            }
        )
        .then((response) => {
          if (type == "identity") {
            this.identity_cities = response.data;
          } else if (type == "domicile") {
            this.domicile_cities = response.data;
          } else {
            this.cities = response.data;
          }
        })
        .catch((e) => {
          this.message = "Error: " + e.response.data.message;
          this.$buefy.snackbar.open({
            message: this.message,
            type: "is-info",
            position: "is-top",
            actionText: "Tutup",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    updateDistrict(type) {
      if (type == "identity") this.getIdentityDistrict(type);
      else if (type == "domicile") this.getDomicileDistrict(type);
      else this.getBothDistrict(type);
    },

    async getDistricts(type, city_id, state_recid) {
      await this.$axios
        .post(process.env.VUE_APP_API_URL + `master-gateway`, {
          code: 'M5',
          CITYRECID: city_id,
          STATERECID: state_recid
        })
        .then((response) => {
          if (type == "identity") {
            this.identity_districts = response.data;
          } else if (type == "domicile") {
            this.domicile_districts = response.data;
          } else {
            this.districts = response.data;
          }
        })
        .catch((e) => {
          this.message = "Error: " + e.response.data.message;
          this.$buefy.snackbar.open({
            message: this.message,
            type: "is-info",
            position: "is-top",
            actionText: "Tutup",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getIdentitySubDistrict() {
      const tempSubDistrict = this.identity_districts.find(
        (item) => item.DISTRICTRECID === this.identity_address.district_id
      );

      this.identity_address.district_name = tempSubDistrict.DESCRIPTION;
    },

    getDomicileSubDistrict() {
      const tempSubDistrict = this.domicile_districts.find(
        (item) => item.DISTRICTRECID === this.domicile_address.district_id
      );

      this.domicile_address.district_name = tempSubDistrict.DESCRIPTION;
    },

    getBothSubDistrict() {
      this.getIdentitySubDistrict();

      if (this.same_address == true) this.getDomicileSubDistrict();
    },

    updateSubDistrict(type) {
      if (type == "identity") this.getIdentitySubDistrict();
      else if (type == "domicile") this.getDomicileSubDistrict();
      else this.getBothSubDistrict();
    },

    resetCounty(type) {
      if (type == "identity") {
        this.identity_address.county_id = null;
        this.identity_address.county_name = null;
        this.identity_counties = [];
        this.resetCity(type);
      } else if (type == "domicile") {
        this.domicile_address.county_id = null;
        this.domicile_address.county_name = null;
        this.domicile_counties = [];
        this.resetCity(type);
      }
    },

    resetCity(type) {
      if (type == "identity") {
        this.identity_address.city_id = null;
        this.identity_address.city_name = null;
        this.identity_cities = [];
        this.resetDistrict(type);
      } else if (type == "domicile") {
        this.domicile_address.city_id = null;
        this.domicile_address.city_name = null;
        this.domicile_cities = [];
        this.resetDistrict(type);
      }
    },

    resetDistrict(type) {
      if (type == "identity") {
        this.identity_address.district_id = null;
        this.identity_address.district_name = null;
        this.identity_districts = [];
      } else if (type == "domicile") {
        this.domicile_address.district_id = null;
        this.domicile_address.district_name = null;
        this.domicile_districts = [];
      }
    },

    isSameAddress() {
      if (this.same_address == true) {
        this.$refs.formDomicile.reset();
        this.domicile_address = { ...this.identity_address };
      }
    },

    prev() {
      if (this.tabs == 1) {
        this.tabs = undefined;
      } else {
        this.setStepperPosition(3);
      }
    },
    partTwo() {
      this.identity_address.full_address = this.identity_address.full_address ? this.identity_address.full_address.replace(/^\s*/, "") : null
      if (this.$refs.formKtp.checkValidity()) {
        if (!(/^[0-9]+$/i.test(this.identity_address.postal_code))) {
          this.$buefy.snackbar.open({
            message: "Gagal! Kode pos KTP hanya bisa angka",
            type: 'is-info',
            position: 'is-top',
            actionText: 'Tutup',
            indefinite: false,
          })
        return false;
        }
        if (this.identity_address.phone_number && !(/^[0-9]+$/i.test(this.identity_address.phone_number))) {
          this.$buefy.snackbar.open({
            message: "Gagal! Nomor telepon KTP hanya bisa angka",
            type: 'is-info',
            position: 'is-top',
            actionText: 'Tutup',
            indefinite: false,
          })
        return false;
        }
        if (!(/^[0-9]+$/i.test(this.identity_address.neighbourhood))) {
          this.$buefy.snackbar.open({
            message: "Gagal! RT KTP hanya bisa angka",
            type: 'is-info',
            position: 'is-top',
            actionText: 'Tutup',
            indefinite: false,
          })
        return false;
        }
        if (!(/^[0-9]+$/i.test(this.identity_address.hamlet))) {
          this.$buefy.snackbar.open({
            message: "Gagal! RW KTP hanya bisa angka",
            type: 'is-info',
            position: 'is-top',
            actionText: 'Tutup',
            indefinite: false,
          })
        return false;
        }
        if (!(/^[A-Za-z0-9\s.,]+$/i.test(this.identity_address.full_address))) {
          this.$buefy.snackbar.open({
            message: "Gagal! Alamat KTP hanya bisa angka, alfabet, tanda titik, koma, dan spasi",
            type: 'is-info',
            position: 'is-top',
            actionText: 'Tutup',
            indefinite: false,
          })
        return false;
        }

        (this.tabs_disabled = false), (this.tabs = 1);
      } else {
        this.$buefy.snackbar.open({
          message: "Gagal! Data alamat KTP kurang/tidak valid, silahkan cek kembali",
          type: "is-info",
          position: "is-top",
          actionText: "Tutup",
        });
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    onlyAlphaNum($event) {
      let char = String.fromCharCode($event.keyCode);
      if (/^[A-Za-z0-9\s.,]+$/i.test(char)) { //including dot and coma
        return true;
      } else {
        $event.preventDefault();
        return false;
      }
    }
  },
};
</script>
