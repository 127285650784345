<template>
  <div>
    <form ref="form">
      <b-modal
        v-model="finish_prompt"
        width="auto"
        scroll="keep"
        has-modal-card
        trap-focus
      >
        <div class="card">
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <p class="title is-5">Menyimpan Data</p>
              </div>
            </div>
            <div class="content">
              Apakah Anda sudah mengisi seluruh data dengan lengkap dan
              sebenar-benarnya?
            </div>
            <b-field>
              <b-button
                expanded
                type="is-info"
                :loading="isLoading"
                @click="goToSecondStep"
                >Ya, simpan data</b-button
              >
            </b-field>
            <b-field>
              <b-button expanded @click="finish_prompt = false"
                >Belum, saya perlu cek kembali</b-button
              >
            </b-field>
          </div>
        </div>
      </b-modal>
      <b-field>
        <b-button class="is-text" icon-left="chevron-left" @click="prev()">
          Sebelumnya
        </b-button>
      </b-field>
      <div class="columns is-desktop">
        <div class="column">
          <b-field>
            <h4 class="title is-6 is-pulled-left">
              18 Pernyataan dan Kesepakatan
            </h4>
          </b-field>
        </div>
        <div class="column">
          <b-field>
            <b-button
              class="button mt-2 is-info is-pulled-right"
              @click="refresh"
              :loading="isLoading"
            >
              Perbarui
            </b-button>
          </b-field>
        </div>
      </div>

      <b-field class="mt-6" label="Kota / Lokasi Pengisian Data Pribadi*">
        <b-input
          v-model="applicant_detail.fill_location"
          placeholder="Kota / Lokasi Pengisian Data Pribadi"
          validation-message="Silahkan isi bagian ini"
          @keypress.native="onlyAlphabet"
          required
          expanded
        ></b-input>
      </b-field>

      <b-field>
        <b-checkbox type="is-info" v-model="agreement" required>
          {{ label_agreement }}
        </b-checkbox>
      </b-field>
      <div class="columns is-mobile is-centered">
        <div class="column">
          <label class="label has-text-centered">Tanda Tangan</label>
          <b-field class="has-addons has-addons-centered">
            <b-image
              :src="
                applicant_detail.updated_signature
                  ? applicant_detail.updated_signature
                  : applicant_detail.signature &&
                    applicant_detail.signature.length > 0
                  ? signature_path + applicant_detail.signature
                  : signature_path + 'no_image.jpeg'
              "
              alt="no image"
              class="is-128x128"
            >
            </b-image>
          </b-field>
        </div>
      </div>
      <div class="columns is-mobile is-centered">
        <div class="column">
          <p class="has-text-centered">
            Silahkan perbarui TTD anda
          </p>
          <b-field class="has-addons has-addons-centered">
            <VueSignaturePad
              :options="{
                onBegin: () => {
                  $refs.signaturePad.resizeCanvas();
                },
              }"
              style="border: 1px black solid"
              width="182px"
              height="182px"
              ref="signaturePad"
            />
          </b-field>
          <b-field class="has-addons has-addons-centered">
            <b-button
              class="button is-info mt-5 mx-5"
              @click="reset"
              :loading="isLoading"
            >
              Hapus Tanda Tangan
            </b-button>
          </b-field>
        </div>
      </div>
      <b-field>
        <b-button
          class="button is-success"
          @click="finish()"
          :loading="isLoading"
          expanded
        >
          Selesai
        </b-button>
      </b-field>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      finish_prompt: false,
      agreement: false,
      label_agreement: null,
      applicant_detail: {
        fill_location: null,
        signature: null,
        updated_signature: null,
        is_editing: false,
      },
      applicant_name: null,
      signature_path: process.env.VUE_APP_SIGNATURE_URL,
    };
  },
  computed: {
    ...mapGetters({
      applicant: "applicant",
      stepper_position: "stepper_position",
    }),
  },
  watch: {
    applicant_name() {
      this.updateLabel();
    },
  },
  async mounted() {
    await this.refresh();
    await this.fetchData();
  },
  methods: {
    ...mapActions({
      setStepperPosition: "setStepperPosition",
    }),
    async goToSecondStep() {
      try {
        this.isLoading = true;
        await this.$axios.put(`applicants/` + this.applicant.id + `/lock`);

        this.$router.go("/file-upload");
      } catch (error) {
        this.message = "Error: " + error.response.data.message;
        this.$buefy.snackbar.open({
          message: this.message,
          type: "is-info",
          position: "is-top",
          actionText: "Tutup",
          indefinite: false,
        });
      }

      this.isLoading = false;
    },
    async finish() {
      var response = await this.update();
      if (response != false) {
        this.finish_prompt = true;
      }
    },
    async fetchData() {
      await this.$axios
        .get(`applicants/` + this.applicant.id)
        .then((response) => {
          console.log(response.data.data.checkpoint);
        })
        .catch((e) => {
          this.message = "Error: " + e.response.data.data.message;
          this.$buefy.snackbar.open({
            message: this.message,
            type: "is-info",
            position: "is-top",
            actionText: "Tutup",
            indefinite: false,
          });
        });
    },
    refresh() {
      this.isLoading = true;

      this.applicant_name =
        this.applicant.first_name && this.applicant.first_name.length > 0
          ? " " + this.applicant.first_name
          : "";
      this.applicant_name +=
        this.applicant.middle_name && this.applicant.middle_name.length > 0
          ? " " + this.applicant.middle_name
          : "";
      this.applicant_name +=
        this.applicant.last_name && this.applicant.last_name.length > 0
          ? " " + this.applicant.last_name
          : "";

      // console.log(this.$refs.signature_image)

      this.$axios
        .get(`applicants/` + this.applicant.id + `/applicant-detail`)
        .then((response) => {
          this.applicant_detail = response.data.data;
          if (
            this.applicant_detail.fill_location &&
            this.applicant_detail.signature
          ) {
            this.applicant_detail.is_editing = true;
            this.agreement = true;
          } else {
            this.applicant_detail.is_editing = true;
          }
        })
        .catch((e) => {
          this.message = "Error: " + e.response.data.message;
          this.$buefy.snackbar.open({
            message: this.message,
            type: "is-info",
            position: "is-top",
            actionText: "Tutup",
            indefinite: false,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateSignature() {
      // this.$refs.signature_container
      this.applicant_detail.is_editing = true;
      // console.log(this.applicant_detail.is_editing)
    },
    updateLabel() {
      this.label_agreement =
        `Dengan ini saya ` +
        this.applicant_name +
        ` menyatakan bahwa keterangan dan dokumen yang saya isi di atas `;

      if (this.applicant_detail.fill_date) {
        this.label_agreement +=
          `pada tanggal ` + this.applicant_detail.fill_date + ` `;
      }

      if (this.applicant_detail.fill_location) {
        this.label_agreement +=
          `di ` + this.applicant_detail.fill_location + ` `;
      }

      this.label_agreement += `adalah benar. Dalam hal ketidak-benaran, maka saya bertanggungjawab penuh atas akibatnya, dan saya bersedia dikenakan sanksi sesuai dengan peraturan perusahaan.`;
    },
    reset() {
      this.$refs.signaturePad.clearSignature();
      this.isLoading = false;
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    update() {
      this.applicant_detail.fill_location = this.applicant_detail.fill_location ? this.applicant_detail.fill_location.replace(/^\s*/, "") : null

      if (!(/^[A-Za-z\s]+$/i.test(this.applicant_detail.fill_location))) {
			this.$buefy.snackbar.open({
					message: "Gagal! Lokasi pengisian data hanya bisa alfabet dan spasi",
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
			return false;
		}

      var signature_empty, signature_data;
      if (this.applicant_detail.is_editing) {
        try {
          const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
          signature_data = data;
          signature_empty = isEmpty;
        } catch (e) {
          if (e.response.status === 401) {
            this.$buefy.snackbar.open({
              message: "Error: " + e.response.data.message,
              type: "is-info",
              position: "is-top",
              actionText: "Tutup",
              indefinite: false,
            });
          } else {
            this.$buefy.snackbar.open({
              message: "Tanda tangan masih kosong",
              type: "is-info",
              position: "is-top",
              actionText: "Tutup",
              indefinite: false,
            });
          }
          
          this.isLoading = false;
          return false;
        }
      } else {
        signature_data = "";
        signature_empty = true;
      }

      if (this.$refs.form.checkValidity()) {
        this.isLoading = true;
        this.message = null;

        if (this.applicant_detail.is_editing) {
          if (signature_empty || signature_data.length <= 0) {
            this.$buefy.snackbar.open({
              message: "Tanda tangan masih kosong",
              type: "is-info",
              position: "is-top",
              actionText: "Tutup",
              indefinite: false,
            });
            this.isLoading = false;
            return false;
          }

          // If user update existing signature, preview the 'updated signature' v-img
          this.applicant_detail.updated_signature = signature_data;

          // this.applicant_detail.signature = data
        }

        this.$axios
          .post(
            `applicants/` + this.applicant.id + `/closing`,
            this.applicant_detail
          )
          .then((response) => {
            if (this.applicant_detail.is_editing) {
              this.applicant_detail.signature = response.data.data.signature;
            }
            this.message = response.data.message;
            this.applicant_detail.is_editing = false;
          })
          .catch((e) => {
            this.$buefy.snackbar.open({
              message: "Error: " + e.response.data.message,
              type: "is-info",
              position: "is-top",
              actionText: "Tutup",
              indefinite: false,
            });
            return false;
          })
          .finally(() => {
            this.isLoading = false;
            // this.showSnackbar({ text: this.message });
          });
      } else {
        this.message = "Gagal! Data kurang/tidak valid, silahkan cek kembali";
        this.$buefy.snackbar.open({
          message: this.message,
          type: "is-info",
          position: "is-top",
          actionText: "Tutup",
          indefinite: false,
        });
        return false;
      }
    },
    prev() {
      this.setStepperPosition(16);
    },
    onlyAlphabet($event) {
			let char = String.fromCharCode($event.keyCode);
			if (/^[A-Za-z\s]+$/i.test(char)) { //check if alphabet
				return true;
			} else {
				$event.preventDefault();
				return false;
			}
		},
  },
};
</script>
