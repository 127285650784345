<template>
	<section>
		<b-modal 
			v-model="indomaret_group_relation_dialog"
			width="auto"
			scroll="keep"
			has-modal-card
			trap-focus
		>
			<form ref="indomaret_group_relation_form">
				<div class="modal-card" style="width: auto">
					<header class="modal-card-head">
						<p class="title is-size-5-desktop is-size-6-mobile">
							{{ indomaret_group_relation_form_title }}
						</p>
					</header>
					<section class="modal-card-body">
						<b-field label="Hubungan*">
							<b-select 
								v-model="active_indomaret_group_relation.reference_type_id"
								placeholder="Hubungan" 
								expanded
								required
							>
								<option
									v-for="reference in reference_type"
									:key="reference.id"
									:value="reference.id" 
									>{{ reference.name }}</option>
							</b-select>
						</b-field>

						<b-field label="Nama Lengkap*">
							<b-input
								v-model="active_indomaret_group_relation.name"
								placeholder="Nama Lengkap"
								validation-message="Silahkan isi bagian ini"
								@keypress.native="onlyAlphabet"
								maxlength="100"
								required
							></b-input>
						</b-field>
						<b-field label="Informasi Jabatan/Departemen">
							<b-input
								v-model="active_indomaret_group_relation.information"
								placeholder="Tidak Wajib, jelaskan jabatan/departemen jika mengetahui"
								@keypress.native="onlyAlphaNumComa"
								maxlength="100"
							></b-input>
						</b-field>
					</section>
					<footer class="modal-card-foot is-pulled-right">
						<b-button 
							class="is-pulled-right"  
							@click="closeIndomaretGroupRelation()"
						>Kembali</b-button>
						<b-button 
							class="is-pulled-right"
							type="is-info" 
							@click="saveIndomaretGroupRelation"
							:loading="isLoading"
						>Simpan</b-button>
					</footer>
				</div>
			</form>
		</b-modal>

		<!-- Modal Pendaftaran Perusahaan Lain -->
		<b-modal 
			v-model="company_applied_dialog"
			width="auto"
			scroll="keep"
			has-modal-card
			trap-focus
		>
			<form ref="active_company_applied_form">
				<div class="modal-card" style="width: auto">
					<header class="modal-card-head">
						<p class="title is-size-5-desktop is-size-6-mobile">
							{{ company_applied_form_title }}
						</p>
					</header>
					<section class="modal-card-body">
						<b-field label="Nama Perusahaan*">
							<b-input
								v-model="active_company_applied.name"
								placeholder="Nama Perusahaan"
								validation-message="Silahkan isi bagian ini"
								@keypress.native="onlyAlphaNum"
								maxlength="100"
								required
							></b-input>
						</b-field>
						<b-field label="Jabatan/Posisi Yang Dilamar*">
							<b-input
								v-model="active_company_applied.position"
								placeholder="Jabatan/Posisi Yang DIlamar"
								validation-message="Silahkan isi bagian ini"
								@keypress.native="onlyAlphaNum"
								maxlength="100"
								required
							></b-input>
						</b-field>
					</section>
					<footer class="modal-card-foot is-pulled-right">
						<b-button 
							class="is-pulled-right"  
							@click="closeCompanyApplied()"
						>Kembali</b-button>
						<b-button 
							class="is-pulled-right"
							type="is-info" 
							@click="saveCompanyApplied"
							:loading="isLoading"
						>Simpan</b-button>
					</footer>
				</div>
			</form>
		</b-modal>

		<b-field>
			<b-button
				class="is-text"
				icon-left="chevron-left"
				@click="prev()"
			>
				Sebelumnya
			</b-button>
		</b-field>
		<div class="columns is-desktop">
			<div class="column">
				<b-field>
					<h4 class="title is-size-5-desktop is-size-6-mobile is-pulled-left"> 
						17 Pertanyaan Lain
					</h4>
				</b-field>
			</div>
			<div class="column">
				<b-field>
					<b-button  
						class="button is-info is-pulled-right" 
						@click="refresh" 
						:loading="isLoading"
					>
						Perbarui
					</b-button>
				</b-field>
			</div>
		</div>
		<b-tabs class="mt-6" v-model="tabs">
			<b-tab-item label="Bagian 1">
				<div class="columns is-mobile">
					<div class="column">
						<label class="label">
							Apakah anda mempunyai teman/sanak 
							saudara yang bekerja di group/perusahaan ini?
						</label>
					</div>
				</div>
				<div class="columns is-mobile">
					<div class="column">
						<b-field>
							<b-button  
								class="button is-success is-pulled-right" 
								@click="addIndomaretGroupRelation" 
								:loading="isLoadingReference"
							>
								Tambah Data
							</b-button>
						</b-field>
					</div>
				</div>
				<b-table
					class="box" 
					:data="indomaret_group_relations.length == 0 ? empty : indomaret_group_relations" 
					:loading="isLoadingReference"
				>
					<b-table-column
						label="Data Relasi"
						:visible="indomaret_group_relations.length == 0"
						v-slot="props" 
					>
						{{ props.row }}
					</b-table-column>
					<b-table-column
						label="Nama"
						:visible="indomaret_group_relations.length != 0"
						v-slot="props" 
					>
						{{ 
							props.row.name + " (" + 
							props.row.reference_type.name + ")" 
						}}
					</b-table-column>
					<b-table-column
						:visible="indomaret_group_relations.length != 0"
						field="action"
						label="Aksi"
						v-slot="props" 
						centered
					>
						<b-button  
							class="button is-warning is-light" 
							@click="editIndomaretGroupRelation(props.row)"
						>Ubah</b-button>
					</b-table-column>
				</b-table>
				<div class="columns is-mobile">
					<div class="column">
						<b-field 
							label="Selain di Indomaret Group, di perusahaan mana lagi Anda melamar? Dimana dan sebagai apa?">
						</b-field>
					</div>
				</div>
				<div class="columns is-mobile">
					<div class="column">
						<b-field class="is-pulled-right">
							<b-button  
								class="button is-success" 
								@click="addCompanyApplied" 
								:loading="isLoadingCompanyApplieds"
							>
								Tambah Data
							</b-button>
						</b-field>
					</div>
				</div>
				<b-table
					class="box" 
					:data="company_applied_list.length == 0 ? empty : company_applied_list" 
					:loading="isLoadingCompanyApplieds"
				>
					<b-table-column
						label="Data Perusahaan"
						:visible="company_applied_list.length == 0"
						v-slot="props" 
					>
						{{ props.row }}
					</b-table-column>
					<b-table-column
						label="Nama Perusahaan"
						:visible="company_applied_list.length != 0"
						v-slot="props" 
					>
						{{ props.row.name }}
					</b-table-column>
					<b-table-column
						:visible="company_applied_list.length != 0"
						field="action"
						label="Aksi"
						v-slot="props" 
						centered
					>
						<b-button  
							class="button is-warning is-light" 
							@click="editCompanyApplied(props.row)"
						>Ubah</b-button>
					</b-table-column>
				</b-table>
				<form ref="partOne">
					<div class="block">
					<b-field label="Pernah mengikuti test di Indomaret Group sebelumnya?*"></b-field>
						<b-field>
							<b-radio 
								v-model="test_attendance"
								type="is-info"
								native-value="Y"
								name="attendance"
								validation-message="Silahkan isi bagian ini"
                required
							>
								Ya
							</b-radio>
						</b-field>
						<b-field>
							<b-radio 
								v-model="test_attendance"
								type="is-info"
								native-value="T"
								required
								name="attendance"
							>
								Tidak
							</b-radio>
						</b-field>
						<div v-if="test_attendance === 'Y'">
							<b-field label="Tanggal Tes Sebelumnya*">
								<b-datepicker
									placeholder="Klik untuk memilih tanggal"
									v-model="recruitment_progress.temp_progress_date"
									@input="savePreviousTestDate"
									:max-date="maxDate"
									:min-date="minDate"
									:mobile-native="false"
									trap-focus
								></b-datepicker>
							</b-field>
							<b-field label="Lokasi Test*">
								<b-input
									v-model="recruitment_progress.location"
									placeholder="Kota/Nama Cabang/Nama Hotel/Kampus/Tempat Lainnya"
									validation-message="Silahkan isi bagian ini"
									:maxlength="maxInputLocation"
									@keypress.native="onlyAlphabet"
									required
								></b-input>
							</b-field>
							<b-field label="Tahapan Test Terakhir*">
								<b-select 
									v-model="recruitment_progress.last_progress"
									placeholder="Tahapan Test Terakhir" 
									expanded
									required
								>
									<option
										v-for="progress in last_recruitment_progress"
										:key="progress"
										:value="progress"
										>{{ progress }}</option>
								</b-select>
							</b-field>
						</div>
					</div>
					<div class="block">
						<b-field label="Apakah Anda pernah menjalani pemeriksaan psikologis/psikotest sebelumnya?*"></b-field>
						<div class="columns is-desktop">
							<div class="column">
								<b-field>
									<b-radio 
										v-model="previous_psychology_test"
										type="is-info"
										native-value="Y"
										required
										name="psychology"
									>
										Ya
									</b-radio>
								</b-field>
								<b-field>
									<b-radio 
										v-model="previous_psychology_test"
										type="is-info"
										native-value="T"
										required
										name="psychology"
									>
										Tidak
									</b-radio>
								</b-field>
								<div v-if="previous_psychology_test === 'Y'">
									<b-field label="Lokasi Psikotest*">
										<b-input
											v-model="psychology_test.location"
											placeholder="Kota/Tempat/Lokasi"
											validation-message="Silahkan isi bagian ini"
											:maxlength="maxInputLocation"
											@keypress.native="onlyAlphabet"
											required
										></b-input>
									</b-field>
									<b-field label="Tujuan Mengikuti Psikotest*">
										<b-select 
											v-model="psychology_test.purpose"
											placeholder="Tujuan Mengikuti Psikotest" 
											expanded
											required
										>
											<option value="Melamar Pekerjaan">
												Melamar Pekerjaan
											</option>
											<option value="Tes Bidang Minat">
												Tes Bidang Minat
											</option>
										</b-select>
									</b-field>
									<b-field label="Hasil Psikotest*">
										<b-input
											v-model="psychology_test.result"
											placeholder="Hasil Psikotest"
											validation-message="Silahkan isi bagian ini"
											:maxlength="maxInputPsikotest"
											@keypress.native="onlyAlphaNumComa"
											required
										></b-input>
									</b-field>
									<b-field label="Tanggal Tes Sebelumnya*">
										<b-datepicker
											placeholder="Klik untuk memilih tanggal"
											v-model="psychology_test.temp_test_date"
											@input="savePreviousPsychotestDate"
											:max-date="maxDate"
											:min-date="minDate"
											:mobile-native="false"
											trap-focus
										></b-datepicker>
									</b-field>
								</div>
							</div>
						</div>
					</div>
				</form>
				<b-field>
					<b-button  
						class="button is-info mt-5" 
						@click="partTwo"
						:loading="isLoading" 
						expanded
					>
						Bagian 2
					</b-button>
				</b-field>
			</b-tab-item>
			<b-tab-item 
				:disabled="tabs_disabled"
				:value="'1'" 
				label="Bagian 2"
			>
				<form ref="form">
					<b-field label="Jenis Pekerjaan yang diminati dan tidak diminati*">
						<b-input 
							v-model="appropriate_work"
							placeholder="Jenis pekerjaan yang sesuai minat anda"
							validation-message="Silahkan isi bagian ini"
							:maxlength="maxInput"
							@keypress.native="onlyAlphaNumComa"
							expanded
							required
						></b-input>
					</b-field>
					<b-field>
						<b-input
							v-model="inappropriate_work" 
							placeholder="Jenis pekerjaan yang tidak sesuai minat anda" 
							validation-message="Silahkan isi bagian ini"
							:maxlength="maxInput"
							@keypress.native="onlyAlphaNumComa"
							expanded
							required
						></b-input>
					</b-field>
					<b-field label="Apakah anda bersedia ditugaskan .... sesuai kebutuhan perusahaan"></b-field>
					<div class="columns is-desktop">
						<div class="column">
							<b-field label="di luar Jabodetabek/luar pulau jawa*">
								<b-radio 
									v-model="question_answer[0].feedback"
									type="is-info"
									native-value="Y"
									required
									name="feedback1"
								>
									Ya
								</b-radio>
							</b-field>
							<b-field>
								<b-radio 
									v-model="question_answer[0].feedback"
									type="is-info"
									native-value="T"
									required
									name="feedback1">
									Tidak
								</b-radio>
							</b-field>
							<b-field 
								v-if="question_answer[0].feedback === 'T'">
								<b-input
									v-model="question_answer[0].reason"
									validation-message="Silahkan isi bagian ini" 
									:maxlength="maxInput"
									placeholder="Alasan"
									@keypress.native="onlyAlphaNumComa"
									required
								></b-input>
							</b-field>
						</div>
						<div class="column">
							<b-field label="di bagian manapun dalam Indomaret Group*">
								<b-radio 
									v-model="question_answer[1].feedback"
									type="is-info"
									native-value="Y"
									required
									name="feedback2"
								>
									Ya
								</b-radio>
							</b-field>
							<b-field>
								<b-radio 
									v-model="question_answer[1].feedback"
									type="is-info"
									native-value="T"
									required
									name="feedback2">
									Tidak
								</b-radio>
							</b-field>
							<b-field 
								v-if="question_answer[1].feedback === 'T'">
								<b-input
									v-model="question_answer[1].reason"
									validation-message="Silahkan isi bagian ini" 
									:maxlength="maxInput"
									placeholder="Alasan"
									@keypress.native="onlyAlphaNumComa"
									required
								></b-input>
							</b-field>
						</div>
					</div>
					<b-field label="Apakah anda bersedia:"></b-field>
					<div class="columns is-desktop">
						<div class="column">
							<b-field label="bekerja di hari Sabtu/Minggu/Libur?*">
								<b-radio 
									v-model="question_answer[2].feedback"
									type="is-info"
									native-value="Y"
									required
									name="feedback3"
								>
									Ya
								</b-radio>
							</b-field>
							<b-field>
								<b-radio 
									v-model="question_answer[2].feedback"
									type="is-info"
									native-value="T"
									required
									name="feedback3">
									Tidak
								</b-radio>
							</b-field>
							<b-field 
								v-if="question_answer[2].feedback === 'T'">
								<b-input
									v-model="question_answer[2].reason" 
									validation-message="Silahkan isi bagian ini"
									:maxlength="maxInput"
									placeholder="Alasan"
									@keypress.native="onlyAlphaNumComa"
									required
								></b-input>
							</b-field>
						</div>
						<div class="column">
							<b-field label="Apakah anda bersedia bekerja Shift?*">
								<b-radio 
									v-model="question_answer[3].feedback"
									type="is-info"
									native-value="Y"
									required
									name="feedback4"
								>
									Ya
								</b-radio>
							</b-field>
							<b-field>
								<b-radio 
									v-model="question_answer[3].feedback"
									type="is-info"
									native-value="T"
									required
									name="feedback4"
								>
									Tidak
								</b-radio>
							</b-field>
							<b-field 
								v-if="question_answer[3].feedback === 'T'">
								<b-input
									v-model="question_answer[3].reason"
									validation-message="Silahkan isi bagian ini" 
									:maxlength="maxInput"
									placeholder="Alasan"
									@keypress.native="onlyAlphaNumComa"
									required
								></b-input>
							</b-field>
						</div>
					</div>
					<b-field label="Apakah anda ada rencana untuk:"></b-field>
					<div class="columns is-desktop">
						<div class="column">
							<b-field label="Menikah?*">
								<b-radio 
									v-model="question_answer[4].feedback"
									type="is-info"
									native-value="Y"
									required
									name="feedback5"
								>
									Ya
								</b-radio>
							</b-field>
							<b-field>
								<b-radio 
									v-model="question_answer[4].feedback"
									type="is-info"
									native-value="T"
									required
									name="feedback5"
								>
									Tidak
								</b-radio>
							</b-field>
							<b-field 
								v-if="question_answer[4].feedback === 'Y'">
								<b-input
									@keypress.native="onlyNumber"
									v-model="question_answer[4].reason" 
									validation-message="Silahkan isi bagian ini"
									placeholder="Usia" 
									maxlength="2"
									required
								></b-input>
							</b-field>
						</div>
						<div class="column">
							<b-field 
								label="Merintis usaha/melanjutkan usaha Orang Tua?*">
							</b-field>
							<div class="columns is-desktop">
								<div class="column">
									<b-field>
										<b-radio 
											v-model="question_answer[5].feedback"
											type="is-info"
											native-value="Y"
											required
											name="feedback6"
										>
											Ya
										</b-radio>
									</b-field>
									<b-field>
										<b-radio 
											v-model="question_answer[5].feedback"
											type="is-info"
											native-value="T"
											required
											name="feedback6"
										>
											Tidak
										</b-radio>
									</b-field>
									<b-field 
										v-if="question_answer[5].feedback === 'Y'">
										<b-input
											v-model="question_answer[5].reason" 
											placeholder="Keterangan/Usaha Dalam Bidang Apa"
											:maxlength="maxInput"
											validation-message="Silahkan isi bagian ini"
											@keypress.native="onlyAlphaNumComa"
											required
										></b-input>
									</b-field>
								</div>
							</div>
						</div>
					</div>
					<b-field label="Meneruskan kuliah?*">
						<b-radio 
							v-model="planning_future_study"
							type="is-info"
							native-value="Y"
							required
							name="studyPlan"
						>
							Ya
						</b-radio>
					</b-field>
					<b-field>
						<b-radio 
							v-model="planning_future_study"
							type="is-info"
							native-value="T"
							required
							name="studyPlan"
						>
							Tidak
						</b-radio>
						<div v-if="planning_future_study === 'Y'">
							<b-field label="Tingkat Pendidikan*">
								<b-select 
									v-model="future_study.level"
									placeholder="Tingkat Pendidikan" 
									expanded
									required
								>
									<option value="D1">D1</option>
									<option value="D2">D2</option>
									<option value="D3">D3</option>
									<option value="S1">S1</option>
									<option value="S2">S2</option>
									<option value="S3">S3</option>
								</b-select>
							</b-field>
							<b-field label="Usia*">
								<b-input
									@keypress.native="onlyNumber"
									v-model="future_study.age"
									placeholder="Tahun"
									validation-message="Silahkan isi bagian ini"
									maxlength="2"
									required
								></b-input>
							</b-field>
							<b-field label="Nama Institusi/Universitas*">
								<b-input
									v-model="future_study.university"
									placeholder="Nama Institusi/Universitas"
									validation-message="Silahkan isi bagian ini"
									@keypress.native="onlyAlphaNum"
									maxlength="100"
									required
								></b-input>
							</b-field>
							<b-field label="Jurusan*">
								<b-input
									v-model="future_study.majoring"
									placeholder="Jurusan"
									validation-message="Silahkan isi bagian ini"
									@keypress.native="onlyAlphabet"
									maxlength="100"
									required
								></b-input>
							</b-field>
							<b-field label="Jenis Perkuliahan*">
								<b-select 
									v-model="future_study.class_type"
									placeholder="Jenis Perkuliahan" 
									validation-message="Silahkan isi bagian ini"
									expanded
									required
								>
									<option
										v-for="(type, index) in class_types"
										:key="index"
										:value="type.value"
									>
										{{ type.text }}
									</option>
								</b-select>
							</b-field>
						</div>
					</b-field>
					<b-field label="Kapan anda dapat mulai bekerja di perusahaan ini?*">
						<b-datepicker
							placeholder="Klik untuk memilih tanggal"
							v-model="temp_start_work"
							@input="saveStartWorkDate"
							:max-date="maxDateWork"
							:min-date="minDateWork"
							:mobile-native="false"
							trap-focus
						></b-datepicker>
					</b-field>
					<b-field 
						label="Berapa besar gaji yang anda harapkan?* (Gaji net, non nego)" :message="convertToRupiah">
						<b-input 
							v-model="expected_salary"
							@keypress.native="onlyNumber"
							required
						>
						</b-input>
						<!-- <b-input
							:disabled="true"
							v-model="convertToRupiah"
							expanded
						>
						</b-input> -->
					</b-field>
					<b-field>
						<b-button  
							class="button is-info mt-5" 
							:loading="isLoadingCompanyApplieds || isLoadingReference"
							@click="next()" 
							expanded
						>
							Simpan & Lanjut
						</b-button>
					</b-field>
				</form>
			</b-tab-item>
		</b-tabs>
	</section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { dateFormat } from '@/plugins/timeformat.js'
export default {
  data() {
		const today = new Date()
		return {
			isLoadingReference: false,
			isLoadingCompanyApplieds: false,
			isLoading: false,
			tabs: undefined,
			tabs_disabled: true,

			empty: ['Belum ada data'],

			reference_type: [],
      position: [],
      indomaret_group_relation_dialog: false,
      company_applied_dialog: false,
      edited_index_indomaret_group_relation: -1,
			edited_index_company_applied: -1,
			start_work: null,
			temp_start_work: null,

			class_types: [
        {
          value: "Reguler",
          text: "Kelas Reguler"
        },
        {
          value: "Karyawan",
          text: "Kelas Karyawan"
        }
			],
			
			previous_psychology_test: false,
      psychology_test: {
        location: null,
        purpose: null,
        result: null,
        test_date_menu: false,
        test_date: null,
        applicant_id: null
      },

      test_attendance: null,
      recruitment_progress: {
        progress_date: null,
        progress_date_menu: false,
        location: null,
        last_progress: null,
        pic: null
      },

      company_applied_list: [],

      active_company_applied: {
        name: null,
        position: null
      },
      active_company_applied_default: {
        name: null,
        position: null
			},
			
			appropriate_work: null,
      inappropriate_work: null,
      expected_salary: null,

      all_location_placement: null,
      all_department_placement: null,

      planning_future_study: null,
      future_study: {
        level: null,
        age: null,
        university: null,
        majoring: null,
        class_type: null
      },

      question_answer: [
        { feedback: null, reason: null, flag: 1 },
        { feedback: null, reason: null, flag: 2 },
        { feedback: null, reason: null, flag: 3 },
        { feedback: null, reason: null, flag: 4 },
        { feedback: null, reason: null, flag: 5 },
        { feedback: null, reason: null, flag: 6 }
			],
			
			indomaret_group_relations: [],

      active_indomaret_group_relation: {
        reference_type_id: null,
        reference_type: {
          name: null
        },
        name: null,
        gender: null,
        position_id: null,
        working_period: null,
        phone_number: null
      },
      active_indomaret_group_relation_default: {
        reference_type_id: null,
        reference_type: {
          name: null
        },
        name: null,
        gender: null,
        position_id: null,
        department: null,
        working_period: null,
        phone_number: null
			},

      departments: [
        "Tidak Ada Masalah",
        "IT Software Developer",
        "IT DBA",
        "HRD",
        "Marketing Franchise",
        "Operational",
        "IT Support"
			],
			last_recruitment_progress: [
				'Psikotest', 
				'Interview Awal (HRD)', 
				'Interview User', 
				'Interview Akhir', 
				'Penawaran Kontrak Kerja (PKWT)'
			],
			minDate: new Date(today.getFullYear() - 58, today.getMonth(), today.getDate()),
			maxDate: new Date(today.getFullYear() + 50, today.getMonth(), today.getDate()),
			minDateWork: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
			maxDateWork: new Date(today.getFullYear(), today.getMonth() + 4, today.getDate()),
			maxInput: 60,
			maxInputPsikotest: 50,
			maxInputLocation: 100
		}
	},
	mounted() {
		this.refresh()
	},
	computed: {
		...mapGetters({
      applicant: "applicant",
			stepper_position: "stepper_position",
    }),
    convertToRupiah() {
      var output = "";
      if (this.expected_salary) {
        var value = this.expected_salary;
        var rupiah = "";
        var final_value = value
          .toString()
          .split("")
          .reverse()
          .join("");
        for (var i = 0; i < final_value.length; i++)
          if (i % 3 == 0) rupiah += final_value.substr(i, 3) + ".";

        output =
          "Rp. " +
          rupiah
            .split("", rupiah.length - 1)
            .reverse()
            .join("");
      }
      return output;
    },
    indomaret_group_relation_form_title() {
      return this.edited_index_indomaret_group_relation === -1
        ? "Tambah Data Relasi Indomaret Group"
        : "Ubah Data Relasi Indomaret Group";
    },
    company_applied_form_title() {
      return this.edited_index_company_applied === -1
        ? "Tambah Data Perusahaan Yang Dilamar"
        : "Ubah Data Perusahaan Yang Dilamar";
    }
	},
	methods: {
		...mapActions({
      setApplicant: "setApplicant",
			setStepperPosition: "setStepperPosition",
    }),
    async next() {
      var response = await this.update();

      if (response != false) {
				try {
					await this.$axios.put(`applicants/` + this.applicant.id + `/checkpoint`);
					console.log(this.applicant.checkpoint)

					this.setStepperPosition(17);
				} catch (error) {
					this.message = "Error: " + error.response.data.message;
					this.$buefy.snackbar.open({
						message: this.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				}
      }
    },
    async refresh() {
      await this.$axios
        .get("applicants/" + this.applicant.id + "/questions")
        .then(response => {
					let data = response.data.data;

          if (data.recruitment_progress) {
						this.recruitment_progress = data.recruitment_progress;
						this.recruitment_progress.temp_progress_date = data.recruitment_progress.progress_date == null 
							? null 
							: new Date(data.recruitment_progress.progress_date)
            this.test_attendance = "Y";
          } else {
            this.test_attendance = "T";
          }

          if (data.psychology_test) {
						this.psychology_test = data.psychology_test;
						this.psychology_test.temp_test_date = data.psychology_test.test_date == null 
							? null
							: new Date(data.psychology_test.test_date)
            this.previous_psychology_test = "Y";
          } else {
            this.previous_psychology_test = "T";
          }

          if (data.question_answers) {
            data.question_answers.forEach(element => {
              let index = element.flag - 1;
              this.question_answer[index].feedback = element.feedback;
              this.question_answer[index].reason = element.reason;
            });
          }
        })
        .catch(e => {
          this.message = "Error: " + e.response.data.message;
          this.$buefy.snackbar.open({
						message: this.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
        });

      await this.refreshReference();
      await this.refreshCompanyApplied();
      await this.refreshApplicantDetail();
			await this.refreshFutureStudy();
		},
		


    async update() {
			this.recruitment_progress.location = this.recruitment_progress.location ? this.recruitment_progress.location.replace(/^\s*/, "") : null
			this.psychology_test.location = this.psychology_test.location ? this.psychology_test.location.replace(/^\s*/, "") : null
			this.psychology_test.result = this.psychology_test.result ? this.psychology_test.result.replace(/^\s*/, "") : null
			this.psychology_test.result = this.psychology_test.result != null ? this.psychology_test.result.length == 1 && (this.psychology_test.result == 0 || this.psychology_test.result == "0") ? "" : this.psychology_test.result : null
this.psychology_test.result = this.psychology_test.result != null ? this.psychology_test.result.length > 1 && this.psychology_test.result.trim().length === 1 && (this.psychology_test.result.charAt(0) == 0 || this.psychology_test.result.charAt(0) == "0") ? "" : this.psychology_test.result : null
			this.appropriate_work = this.appropriate_work ? this.appropriate_work.replace(/^\s*/, "") : null
			this.appropriate_work = this.appropriate_work != null ? this.appropriate_work.length == 1 && (this.appropriate_work == 0 || this.appropriate_work == "0") ? "" : this.appropriate_work : null
this.appropriate_work = this.appropriate_work != null ? this.appropriate_work.length > 1 && this.appropriate_work.trim().length === 1 && (this.appropriate_work.charAt(0) == 0 || this.appropriate_work.charAt(0) == "0") ? "" : this.appropriate_work : null
			this.inappropriate_work = this.inappropriate_work ? this.inappropriate_work.replace(/^\s*/, "") : null
			this.inappropriate_work = this.inappropriate_work != null ? this.inappropriate_work.length == 1 && (this.inappropriate_work == 0 || this.inappropriate_work == "0") ? "" : this.inappropriate_work : null
this.inappropriate_work = this.inappropriate_work != null ? this.inappropriate_work.length > 1 && this.inappropriate_work.trim().length === 1 && (this.inappropriate_work.charAt(0) == 0 || this.inappropriate_work.charAt(0) == "0") ? "" : this.inappropriate_work : null
			this.question_answer[0].reason = this.question_answer[0].reason ? this.question_answer[0].reason.replace(/^\s*/, "") : null
			this.question_answer[0].reason = this.question_answer[0].reason != null ? this.question_answer[0].reason.length == 1 && (this.question_answer[0].reason == 0 || this.question_answer[0].reason == "0") ? "" : this.question_answer[0].reason : null
this.question_answer[0].reason = this.question_answer[0].reason != null ? this.question_answer[0].reason.length > 1 && this.question_answer[0].reason.trim().length === 1 && (this.question_answer[0].reason.charAt(0) == 0 || this.question_answer[0].reason.charAt(0) == "0") ? "" : this.question_answer[0].reason : null
			this.question_answer[1].reason = this.question_answer[1].reason ? this.question_answer[1].reason.replace(/^\s*/, "") : null
			this.question_answer[1].reason = this.question_answer[1].reason != null ? this.question_answer[1].reason.length == 1 && (this.question_answer[1].reason == 0 || this.question_answer[1].reason == "0") ? "" : this.question_answer[1].reason : null
this.question_answer[1].reason = this.question_answer[1].reason != null ? this.question_answer[1].reason.length > 1 && this.question_answer[1].reason.trim().length === 1 && (this.question_answer[1].reason.charAt(0) == 0 || this.question_answer[1].reason.charAt(0) == "0") ? "" : this.question_answer[1].reason : null
			this.question_answer[2].reason = this.question_answer[2].reason ? this.question_answer[2].reason.replace(/^\s*/, "") : null
			this.question_answer[2].reason = this.question_answer[2].reason != null ? this.question_answer[2].reason.length == 1 && (this.question_answer[2].reason == 0 || this.question_answer[2].reason == "0") ? "" : this.question_answer[2].reason : null
this.question_answer[2].reason = this.question_answer[2].reason != null ? this.question_answer[2].reason.length > 1 && this.question_answer[2].reason.trim().length === 1 && (this.question_answer[2].reason.charAt(0) == 0 || this.question_answer[2].reason.charAt(0) == "0") ? "" : this.question_answer[2].reason : null
			this.question_answer[3].reason = this.question_answer[3].reason ? this.question_answer[3].reason.replace(/^\s*/, "") : null
			this.question_answer[3].reason = this.question_answer[3].reason != null ? this.question_answer[3].reason.length == 1 && (this.question_answer[3].reason == 0 || this.question_answer[3].reason == "0") ? "" : this.question_answer[3].reason : null
			this.question_answer[3].reason = this.question_answer[3].reason != null ? this.question_answer[3].reason.length > 1 && this.question_answer[3].reason.trim().length === 1 && (this.question_answer[3].reason.charAt(0) == 0 || this.question_answer[3].reason.charAt(0) == "0") ? "" : this.question_answer[3].reason : null
			this.question_answer[4].reason = this.question_answer[4].reason != null ? this.question_answer[4].reason.length == 1 && (this.question_answer[4].reason == 0 || this.question_answer[4].reason == "0") ? "" : this.question_answer[4].reason : null
this.question_answer[4].reason = this.question_answer[4].reason != null ? this.question_answer[4].reason.length > 1 && this.question_answer[4].reason.trim().length === 1 && (this.question_answer[4].reason.charAt(0) == 0 || this.question_answer[4].reason.charAt(0) == "0") ? "" : this.question_answer[4].reason : null
			this.question_answer[5].reason = this.question_answer[5].reason ? this.question_answer[5].reason.replace(/^\s*/, "") : null
			this.question_answer[5].reason = this.question_answer[5].reason != null ? this.question_answer[5].reason.length == 1 && (this.question_answer[5].reason == 0 || this.question_answer[5].reason == "0") ? "" : this.question_answer[5].reason : null
this.question_answer[5].reason = this.question_answer[5].reason != null ? this.question_answer[5].reason.length > 1 && this.question_answer[5].reason.trim().length === 1 && (this.question_answer[5].reason.charAt(0) == 0 || this.question_answer[5].reason.charAt(0) == "0") ? "" : this.question_answer[5].reason : null
			this.future_study.university = this.future_study.university ? this.future_study.university.replace(/^\s*/, "") : null
			this.future_study.university = this.future_study.university != null ? this.future_study.university.length == 1 && (this.future_study.university == 0 || this.future_study.university == "0") ? "" : this.future_study.university : null
			this.future_study.university = this.future_study.university != null ? this.future_study.university.length > 1 && this.future_study.university.trim().length === 1 && (this.future_study.university.charAt(0) == 0 || this.future_study.university.charAt(0) == "0") ? "" : this.future_study.university : null
			this.future_study.age = this.future_study.age != null ? this.future_study.age.length == 1 && (this.future_study.age == 0 || this.future_study.age == "0") ? "" : this.future_study.age : null
this.future_study.age = this.future_study.age != null ? this.future_study.age.length > 1 && this.future_study.age.trim().length === 1 && (this.future_study.age.charAt(0) == 0 || this.future_study.age.charAt(0) == "0") ? "" : this.future_study.age : null
			this.future_study.majoring = this.future_study.majoring ? this.future_study.majoring.replace(/^\s*/, "") : null

			if(this.test_attendance === 'Y' && this.recruitment_progress.progress_date == null) {
				this.message = "Gagal! Data bagian 1 kurang/tidak valid, silahkan cek kembali";
				this.$buefy.snackbar.open({
					message: this.message,
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
				return false
			}
			if(this.previous_psychology_test === 'Y' && this.psychology_test.test_date == null) {
				this.message = "Gagal! Data bagian 1 kurang/tidak valid, silahkan cek kembali";
				this.$buefy.snackbar.open({
					message: this.message,
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
				return false
			}
			if (!this.$refs.partOne.checkValidity()) {
				this.message = "Gagal! Data bagian 1 kurang/tidak valid, silahkan cek kembali";
				this.$buefy.snackbar.open({
					message: this.message,
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
				return false
			}
      if (this.$refs.form.checkValidity() && this.temp_start_work != null) {
		if (!(/^[A-Za-z\s]+$/i.test(this.recruitment_progress.location)) && this.test_attendance == 'Y') {
			this.$buefy.snackbar.open({
					message: "Gagal! Lokasi tes Indomaret Group hanya bisa alfabet dan spasi",
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
			return false;
		}
		if (!(/^[A-Za-z\s]+$/i.test(this.psychology_test.location)) && this.previous_psychology_test == 'Y') {
			this.$buefy.snackbar.open({
					message: "Gagal! Lokasi psikotes yang pernah dilakukan hanya bisa alfabet dan spasi",
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
			return false;
		}
		if (!(/^[A-Za-z0-9\s,]+$/i.test(this.psychology_test.result)) && this.previous_psychology_test == 'Y') {
			this.$buefy.snackbar.open({
					message: "Gagal! Hasil psikotes yang pernah dilakukan hanya bisa alfabet, angka, spasi dan tanda koma",
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
			return false;
		}
		if (!(/^[A-Za-z0-9\s,]+$/i.test(this.appropriate_work))|| !(/^[A-Za-z0-9\s,]+$/i.test(this.inappropriate_work))) {
			this.$buefy.snackbar.open({
					message: "Gagal! Jenis pekerjaan yang diminati atau tidak hanya bisa alfabet, angka, spasi dan tanda koma",
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
			return false;
		}
		if ((!(/^[A-Za-z0-9\s,]+$/i.test(this.question_answer[0].reason)) && this.question_answer[0].feedback == 'T') ||
			(!(/^[A-Za-z0-9\s,]+$/i.test(this.question_answer[1].reason)) && this.question_answer[1].feedback == 'T') ||
			(!(/^[A-Za-z0-9\s,]+$/i.test(this.question_answer[2].reason)) && this.question_answer[2].feedback == 'T') ||
			(!(/^[A-Za-z0-9\s,]+$/i.test(this.question_answer[3].reason)) && this.question_answer[3].feedback == 'T') ||
			(!(/^[A-Za-z0-9\s,]+$/i.test(this.question_answer[5].reason)) && this.question_answer[5].feedback == 'Y')) {
			this.$buefy.snackbar.open({
					message: "Gagal! Alasan setiap pertanyaan dan jenis usaha orang tua hanya bisa alfabet, angka, spasi dan tanda koma",
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
			return false;
		}
		if ((!(/^[0-9]+$/i.test(this.question_answer[4].reason)) && this.question_answer[4].feedback == 'Y')) {
			this.$buefy.snackbar.open({
					message: "Gagal! Usia menikah hanya bisa angka",
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
			return false;
		}
		if (!(/^[0-9]+$/i.test(this.future_study.age)) && this.planning_future_study == 'Y') {
			this.$buefy.snackbar.open({
					message: "Gagal! Usia kuliah hanya bisa angka",
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
			return false;
		}
		if (this.planning_future_study == 'Y' && !(/^[A-Za-z0-9\s]+$/i.test(this.future_study.university))) {
			this.$buefy.snackbar.open({
					message: "Gagal! Nama universitas hanya bisa alfabet, angka dan spasi",
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
			return false;
		}
		if (this.planning_future_study == 'Y' && !(/^[A-Za-z\s]+$/i.test(this.future_study.majoring))) {
			this.$buefy.snackbar.open({
					message: "Gagal! Nama jurusan hanya bisa alfabet dan spasi",
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
			return false;
		}
		if (!(/^[0-9]+$/i.test(this.expected_salary))) {
			this.$buefy.snackbar.open({
					message: "Gagal! Harapan gaji hanya bisa angka",
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
			return false;
		}
		
        this.form_save_loading = true;
        this.message = null;

        let array = {};

		if (this.test_attendance === 'T') {
			this.noAttendance();
		}
		if (this.previous_psychology_test === 'T') {
			this.noPsychoTest();
		}
		if (this.planning_future_study === 'T') {
			this.noFutureStudyPlan();
		}
		if (this.question_answer[0].feedback === 'Y') {
			this.question_answer[0].reason = '';
		}
		if (this.question_answer[1].feedback === 'Y') {
			this.question_answer[1].reason = '';
		}
		if (this.question_answer[2].feedback === 'Y') {
			this.question_answer[2].reason = '';
		}
		if (this.question_answer[3].feedback === 'Y') {
			this.question_answer[3].reason = '';
		}
		if (this.question_answer[4].feedback === 'T') {
			this.question_answer[4].reason = '';
		}
		if (this.question_answer[5].feedback === 'T') {
			this.question_answer[5].reason = '';
		}

        array.psychology_test = this.psychology_test;
        array.recruitment_progress = this.recruitment_progress;
        array.psychology_test.applicant_id = this.applicant.id;
        array.recruitment_progress.applicant_id = this.applicant.id;

        array.appropriate_work = this.appropriate_work;
        array.inappropriate_work = this.inappropriate_work;
        array.question_answer = this.question_answer;

        if (this.planning_future_study === "Y") {
          array.future_study = this.future_study;
          array.future_study.applicant_id = this.applicant.id;
        } else {
          array.future_study = "T";
        }

        array.start_work = this.start_work;
        array.expected_salary = this.expected_salary;

        await this.$axios
          .post(`applicants/` + this.applicant.id + `/questions`, array)
          .then(response => {
            this.message = response.data.message;
          })
          .catch(e => {
            this.message = "Error: " + e.response.data.message;
            return false;
          })
          .finally(() => {
            this.form_save_loading = false;
            this.$buefy.snackbar.open({
							message: this.message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
          });
      } else {
        this.message = "Gagal! Data bagian 2 kurang/tidak valid, silahkan cek kembali";
        this.$buefy.snackbar.open({
					message: this.message,
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
        return false;
      }
    },
    updateReferenceTypeName() {
      for (var i = 0; i < this.reference_type.length; i++) {
        if (
          this.reference_type[i].id ===
          this.active_indomaret_group_relation.reference_type_id
        ) {
          this.active_indomaret_group_relation.reference_type.name = this.reference_type[
            i
          ].name;
          break;
        }
      }
    },
    refreshReference() {
      this.isLoadingReference = true;
      this.$axios
        .get("applicants/" + this.applicant.id + "/references")
        .then(response => {
          this.indomaret_group_relations = response.data.data;
        })
        .catch(e => {
          this.message = "Error: " + e.response.data.message;
          this.$buefy.snackbar.open({
						message: this.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
        })
        .finally(() => {
          this.isLoadingReference = false;
        });

      if (!this.reference_type.length) {
        this.$axios
          .get("reference-types")
          .then(response => {
            this.reference_type = response.data.data;
          })
          .catch(e => {
            this.message = "Error: " + e.response.data.message;
            this.$buefy.snackbar.open({
							message: this.message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
          });
      }

      if (!this.position.length) {
        this.$axios
          .get("positions")
          .then(response => {
            this.position = response.data.data;
          })
          .catch(e => {
            this.message = "Error: " + e.response.data.message;
            this.$buefy.snackbar.open({
							message: this.message,
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
          });
      }
    },
    refreshCompanyApplied() {
      this.isLoadingCompanyApplieds = true;
      this.$axios
        .get("applicants/" + this.applicant.id + "/company-applieds")
        .then(response => {
          this.company_applied_list = response.data.data;
        })
        .catch(e => {
          this.message = "Error: " + e.response.data.message;
          this.$buefy.snackbar.open({
						message: this.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
        })
        .finally(() => {
          this.isLoadingCompanyApplieds = false;
        });
    },
    refreshFutureStudy() {
      this.$axios
        .get("applicants/" + this.applicant.id + "/future-study")
        .then(response => {
			if (response.data.data) {
				if (response.data.data.level && response.data.data.university) {
					this.future_study = response.data.data;
					this.planning_future_study = "Y";
				} else {
					this.planning_future_study = "T";
				}
			}
        })
        .catch(e => {
          this.message = "Error: " + e.response.data.message;
          this.$buefy.snackbar.open({
						message: this.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
        });
    },
    refreshApplicantDetail() {
      this.$axios
        .get("applicants/" + this.applicant.id + "/applicant-detail")
        .then(response => {
          this.expected_salary = response.data.data.expected_salary;
			if (response.data.data.start_work == null) {
				this.temp_start_work = null
				this.start_work = null
			} else {
				this.temp_start_work = new Date(response.data.data.start_work)
				this.start_work = response.data.data.start_work
			}
          this.appropriate_work = response.data.data.appropriate_work;
					this.inappropriate_work = response.data.data.inappropriate_work;
        })
        .catch(e => {
					console.log(e.response.data.message)
          this.message = "Error: " + e.response.data.message;
          this.$buefy.snackbar.open({
						message: this.message,
						type: 'is-info',
						position: 'is-top',
						actionText: 'Tutup',
						indefinite: false,
					})
				});
		},
    saveStartWorkDate(date) {
      this.start_work = dateFormat(date);
    },
    savePreviousTestDate(date) {
      this.recruitment_progress.progress_date = dateFormat(date);
    },
    savePreviousPsychotestDate(date) {
      this.psychology_test.test_date = dateFormat(date);
    },
    addIndomaretGroupRelation() {
      this.closeIndomaretGroupRelation();
      this.indomaret_group_relation_dialog = true;
    },
    closeIndomaretGroupRelation() {
      this.indomaret_group_relation_dialog = false;
      this.isLoading = false;
      this.active_indomaret_group_relation = Object.assign(
        {},this.active_indomaret_group_relation_default
      );
      this.edited_index_indomaret_group_relation = -1;
    },
    saveIndomaretGroupRelation(e) {
		this.active_indomaret_group_relation.name = this.active_indomaret_group_relation.name ? this.active_indomaret_group_relation.name.replace(/^\s*/, "") : null
		this.active_indomaret_group_relation.information = this.active_indomaret_group_relation.information ? this.active_indomaret_group_relation.information.replace(/^\s*/, "") : null
		this.active_indomaret_group_relation.information = this.active_indomaret_group_relation.information != null ? this.active_indomaret_group_relation.information.length == 1 && (this.active_indomaret_group_relation.information == 0 || this.active_indomaret_group_relation.information == "0") ? "" : this.active_indomaret_group_relation.information : null
this.active_indomaret_group_relation.information = this.active_indomaret_group_relation.information != null ? this.active_indomaret_group_relation.information.length > 1 && this.active_indomaret_group_relation.information.trim().length === 1 && (this.active_indomaret_group_relation.information.charAt(0) == 0 || this.active_indomaret_group_relation.information.charAt(0) == "0") ? "" : this.active_indomaret_group_relation.information : null

      if (this.$refs.indomaret_group_relation_form.checkValidity()) {
		if (!(/^[A-Za-z\s]+$/i.test(this.active_indomaret_group_relation.name))) {
			this.$buefy.snackbar.open({
					message: "Gagal! Nama hanya bisa alfabet dan spasi",
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
			return false;
		}
		if (this.active_indomaret_group_relation.information && !(/^[A-Za-z0-9\s,]+$/i.test(this.active_indomaret_group_relation.information))) {
			this.$buefy.snackbar.open({
					message: "Gagal! Informasi hanya bisa alfabet, angka, spasi dan tanda koma",
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
			return false;
		}

        this.isLoading = true;
        this.active_indomaret_group_relation.applicant_id = this.applicant.id;
        this.message = null;

        if (this.edited_index_indomaret_group_relation > -1) {
          this.$axios
            .patch(
              `applicants/` +
                this.applicant.id +
                `/references/` +
                this.active_indomaret_group_relation.id,
              this.active_indomaret_group_relation
            )
            .then(response => {
              this.message = response.data.message;
			this.updateReferenceTypeName();

              if (response.data.status) {
                Object.assign(
                  this.indomaret_group_relations[
                    this.edited_index_indomaret_group_relation
                  ],
                  this.active_indomaret_group_relation
                );
              }
            })
            .catch(e => {
              this.message = "Error: " + e.response.data.message;
            })
            .finally(() => {
              this.closeIndomaretGroupRelation();
              this.$buefy.snackbar.open({
								message: this.message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
            });
        } else {
          this.$axios
            .post(
              `applicants/` + this.applicant.id + `/references`,
              this.active_indomaret_group_relation
            )
            .then(response => {
              this.message = response.data.message;

              if (response.data.status) {
                this.active_indomaret_group_relation.id = response.data.data.id;
                this.indomaret_group_relations.push(
                  this.active_indomaret_group_relation
                );
              }
            })
            .catch(e => {
              this.message = "Error: " + e.response.data.message;
            })
            .finally(() => {
              this.closeIndomaretGroupRelation();
              this.$buefy.snackbar.open({
								message: this.message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
            });
        }
      } else {
        this.message = "Gagal! Data kurang/tidak valid, silahkan cek kembali";
        this.$buefy.snackbar.open({
					message: this.message,
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
      }

      e.preventDefault();
    },
    editIndomaretGroupRelation(indomaret_group_relation) {
      this.edited_index_indomaret_group_relation = this.indomaret_group_relations.indexOf(
        indomaret_group_relation
      );
      this.active_indomaret_group_relation = Object.assign(
        {},
        indomaret_group_relation
      );
      this.indomaret_group_relation_dialog = true;
    },
    deleteIndomaretGroupRelation(indomaret_group_relation) {
      const index = this.indomaret_group_relations.indexOf(
        indomaret_group_relation
      );
      confirm("Yakin menghapus data relasi?") &&
        this.indomaret_group_relations.splice(index, 1);
    },
    addCompanyApplied() {
      this.closeCompanyApplied();
      this.company_applied_dialog = true;
    },
    closeCompanyApplied() {
      this.company_applied_dialog = false;
      this.save_company_applied_loading = false;
      this.active_company_applied = Object.assign(
        {},
        this.active_company_applied_default
      );
      this.edited_index_company_applied = -1;
    },
    saveCompanyApplied(e) {
		this.active_company_applied.name = this.active_company_applied.name ? this.active_company_applied.name.replace(/^\s*/, "") : null
		this.active_company_applied.name = this.active_company_applied.name != null ? this.active_company_applied.name.length == 1 && (this.active_company_applied.name == 0 || this.active_company_applied.name == "0") ? "" : this.active_company_applied.name : null
this.active_company_applied.name = this.active_company_applied.name != null ? this.active_company_applied.name.length > 1 && this.active_company_applied.name.trim().length === 1 && (this.active_company_applied.name.charAt(0) == 0 || this.active_company_applied.name.charAt(0) == "0") ? "" : this.active_company_applied.name : null
		this.active_company_applied.position = this.active_company_applied.position ? this.active_company_applied.position.replace(/^\s*/, "") : null
		this.active_company_applied.position = this.active_company_applied.position != null ? this.active_company_applied.position.length == 1 && (this.active_company_applied.position == 0 || this.active_company_applied.position == "0") ? "" : this.active_company_applied.position : null
this.active_company_applied.position = this.active_company_applied.position != null ? this.active_company_applied.position.length > 1 && this.active_company_applied.position.trim().length === 1 && (this.active_company_applied.position.charAt(0) == 0 || this.active_company_applied.position.charAt(0) == "0") ? "" : this.active_company_applied.position : null

      if (this.$refs.active_company_applied_form.checkValidity()) {
		if (!(/^[A-Za-z0-9\s]+$/i.test(this.active_company_applied.name)) || !(/^[A-Za-z0-9\s]+$/i.test(this.active_company_applied.position))) {
			this.$buefy.snackbar.open({
					message: "Gagal! Nama perusahaan dan jabatan hanya bisa alfabet, angka dan spasi",
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
			return false;
		}

        this.save_company_applied_loading = true;
        this.active_company_applied.applicant_id = this.applicant.id;
        this.message = null;

        if (this.edited_index_company_applied > -1) {
          this.$axios
            .patch(
              `applicants/` +
                this.applicant.id +
                `/company-applieds/` +
                this.active_company_applied.id,
              this.active_company_applied
            )
            .then(response => {
              this.message = response.data.message;

              if (response.data.status) {
                Object.assign(
                  this.company_applied_list[this.edited_index_company_applied],
                  this.active_company_applied
                );
              }
            })
            .catch(e => {
              this.message = "Error: " + e.response.data.message;
            })
            .finally(() => {
              this.closeCompanyApplied();
              this.$buefy.snackbar.open({
								message: this.message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
            });
        } else {
          this.$axios
            .post(
              `applicants/` + this.applicant.id + `/company-applieds`,
              this.active_company_applied
            )
            .then(response => {
              this.message = response.data.message;

              if (response.data.status) {
                this.active_company_applied.id = response.data.data.id;
                this.company_applied_list.push(this.active_company_applied);
              }
            })
            .catch(e => {
              this.message = "Error: " + e.response.data.message;
            })
            .finally(() => {
              this.closeCompanyApplied();
              this.$buefy.snackbar.open({
								message: this.message,
								type: 'is-info',
								position: 'is-top',
								actionText: 'Tutup',
								indefinite: false,
							})
            });
        }
        // this.company_applied_list.push(this.active_company_applied)
        // this.active_company_applied = this.active_company_applied_default
        // this.company_applied_dialog = false
      } else {
        this.message = "Gagal! Data kurang/tidak valid, silahkan cek kembali";
        this.$buefy.snackbar.open({
					message: this.message,
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
      }

      e.preventDefault();
    },
    editCompanyApplied(company_applied) {
      this.edited_index_company_applied = this.company_applied_list.indexOf(
        company_applied
      );
      this.active_company_applied = Object.assign({}, company_applied);
      this.company_applied_dialog = true;
		},
		prev() {
			if(this.tabs == 1)
				this.tabs = undefined
			else
				this.setStepperPosition(15)
		},
		partTwo() {
			this.recruitment_progress.location = this.recruitment_progress.location ? this.recruitment_progress.location.replace(/^\s*/, "") : null
			this.psychology_test.location = this.psychology_test.location ? this.psychology_test.location.replace(/^\s*/, "") : null
			this.psychology_test.result = this.psychology_test.result ? this.psychology_test.result.replace(/^\s*/, "") : null

			if(this.test_attendance === 'Y' && this.recruitment_progress.progress_date == null) {
				this.message = "Gagal! Data kurang/tidak valid, silahkan cek kembali";
				this.$buefy.snackbar.open({
					message: this.message,
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
				return false
			}
			if(this.previous_psychology_test === 'Y' && this.psychology_test.test_date == null) {
				this.message = "Gagal! Data kurang/tidak valid, silahkan cek kembali";
				this.$buefy.snackbar.open({
					message: this.message,
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
				return false
			}
			if (this.$refs.partOne.checkValidity()) {
				if (!(/^[A-Za-z\s]+$/i.test(this.recruitment_progress.location)) && this.test_attendance == 'Y') {
					this.$buefy.snackbar.open({
							message: "Gagal! Lokasi tes Indomaret Group hanya bisa alfabet dan spasi",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
				}
				if (!(/^[A-Za-z\s]+$/i.test(this.psychology_test.location)) && this.previous_psychology_test == 'Y') {
					this.$buefy.snackbar.open({
							message: "Gagal! Lokasi psikotes yang pernah dilakukan hanya bisa alfabet dan spasi",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
				}
				if (!(/^[A-Za-z0-9\s,]+$/i.test(this.psychology_test.result)) && this.previous_psychology_test == 'Y') {
					this.$buefy.snackbar.open({
							message: "Gagal! Hasil psikotes yang pernah dilakukan hanya bisa alfabet, angka, spasi dan tanda koma",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
				}

				this.tabs_disabled = false,
				this.tabs = 1
			} else {
				this.message = "Gagal! Data kurang/tidak valid, silahkan cek kembali";
				this.$buefy.snackbar.open({
					message: this.message,
					type: 'is-info',
					position: 'is-top',
					actionText: 'Tutup',
					indefinite: false,
				})
			}
		},

		noAttendance() {
			this.recruitment_progress.temp_progress_date = ''
			this.recruitment_progress.progress_date_menu = ''
			this.recruitment_progress.progress_date = ''
			this.recruitment_progress.location = ''
			this.recruitment_progress.last_progress = ''
		},
		noPsychoTest() {
			this.psychology_test.location = ''
			this.psychology_test.purpose = ''
			this.psychology_test.result = ''
			this.psychology_test.test_date = ''
			this.psychology_test.temp_test_date = ''
		},
		noFutureStudyPlan() {
			this.future_study.level = ''
			this.future_study.age = ''
			this.future_study.university = ''
			this.future_study.majoring = ''
			this.future_study.class_type = ''
		},
		onlyNumber($event) {
			let keyCode = $event.keyCode ? $event.keyCode : $event.which;
			if (keyCode < 48 || keyCode > 57) {
				// 46 is dot
				$event.preventDefault();
			}
		},
		onlyAlphabet($event) {
			let char = String.fromCharCode($event.keyCode);
			if (/^[A-Za-z\s]+$/i.test(char)) { //check if alphabet
				return true;
			} else {
				$event.preventDefault();
				return false;
			}
		},
		onlyAlphaNumComa($event) {
			let char = String.fromCharCode($event.keyCode);
			if (/^[A-Za-z0-9\s,]+$/i.test(char)) { //include coma
				return true;
			} else {
				$event.preventDefault();
				return false;
			}
		},
		onlyAlphaNum($event) {
			let char = String.fromCharCode($event.keyCode);
			if (/^[A-Za-z0-9\s]+$/i.test(char)) { //include coma
				return true;
			} else {
				$event.preventDefault();
				return false;
			}
		},
	},
};
</script>