<template>
  <section>
    <b-modal
      v-model="isCardModalActive"
      width="auto"
      scroll="keep"
      has-modal-card
      trap-focus
    >
      <form ref="form">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="title is-size-5-desktop is-size-6-mobile">
              {{ formTitle }}
            </p>
          </header>
          <section class="modal-card-body">
            <div class="columns is-desktop">
              <div class="column">
                <b-field label="Bidang*">
                  <b-input
                    v-model="active_education.scope"
                    placeholder="Bidang"
                    validation-message="Silahkan isi bagian ini"
                    @keypress.native="onlyAlphaNum"
                    maxlength="100"
                    required
                  ></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Penyelenggara*">
                  <b-input
                    v-model="active_education.organizer"
                    placeholder="Penyelenggara"
                    validation-message="Silahkan isi bagian ini"
                    @keypress.native="onlyAlphaNum"
                    maxlength="100"
                    required
                  ></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns is-desktop">
              <div class="column">
                <b-field label="Tempat/Kota*">
                  <b-input
                    v-model="active_education.location"
                    placeholder="Tempat/Kota"
                    validation-message="Silahkan isi bagian ini"
                    @keypress.native="onlyAlphabet"
                    maxlength="50"
                    required
                  ></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Dibiayai Oleh*">
                  <b-input
                    v-model="active_education.paid_by"
                    placeholder="Dibiayai Oleh"
                    validation-message="Silahkan isi bagian ini"
                    @keypress.native="onlyAlphaNum"
                    maxlength="100"
                    required
                  >
                  </b-input>
                </b-field>
              </div>
            </div>
            <div class="columns is-desktop">
              <div class="column">
                <b-field label="Tanggal Mulai*">
                  <b-datepicker
                    placeholder="Klik untuk memilih tanggal"
                    v-model="active_education.temp_start_date"
                    :max-date="maxStartDate"
                    :min-date="minDate"
                    :mobile-native="false"
                    trap-focus
                    @input="saveStartDate"
                  ></b-datepicker>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Tanggal Selesai*">
                  <b-datepicker
                    placeholder="Klik untuk memilih tanggal"
                    v-model="active_education.temp_end_date"
                    :max-date="maxEndDate"
                    :min-date="minDateEnd"
                    :mobile-native="false"
                    trap-focus
                    @input="saveEndDate"
                  ></b-datepicker>
                </b-field>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot is-pulled-right">
            <b-button class="is-pulled-right" @click="closeModal()"
              >Kembali</b-button
            >
            <b-button
              class="is-pulled-right"
              type="is-info"
              @click.prevent="saveEducation"
              :loading="isLoading"
              >Simpan</b-button
            >
          </footer>
        </div>
      </form>
    </b-modal>
    <b-field>
      <b-button class="is-text" icon-left="chevron-left" @click="prev()">
        Sebelumnya
      </b-button>
    </b-field>
    <div class="columns">
      <div class="column">
        <h4 class="title is-size-5-desktop is-size-6-mobile is-pulled-left">
          10 Riwayat Pendidikan Informal/Kursus
        </h4>
      </div>
    </div>
    <div class="columns is-desktop">
      <div class="column is-one-quarter is-offset-9">
        <b-field class="my-5">
          <b-button
            class="button is-success is-pulled-right"
            @click="add()"
            :loading="isLoading"
            expanded
          >
            Tambah Data
          </b-button>
        </b-field>
      </div>
    </div>
    <div>
      <b-field>
        <b-checkbox class="mt-4" type="is-info" v-model="checkbox">
          Tidak memiliki riwayat pendidikan informal
        </b-checkbox>
      </b-field>
    </div>
    <b-table
      class="box"
      :data="educations.length == 0 ? empty : educations"
      :loading="isLoading"
    >
      <b-table-column
        label="Data Pendidikan Informal"
        :visible="educations.length == 0"
        v-slot="props"
      >
        {{ props.row }}
      </b-table-column>
      <b-table-column
        label="Bidang"
        :visible="educations.length != 0"
        v-slot="props"
      >
        {{ props.row.scope + " (" + props.row.organizer + ")" }}
      </b-table-column>
      <b-table-column
        :visible="educations.length != 0"
        field="action"
        label="Aksi"
        v-slot="props"
        centered
      >
        <b-button
          class="button is-warning is-light"
          @click="editEducation(props.row)"
          >Ubah</b-button
        >
      </b-table-column>
    </b-table>
    <b-field>
      <b-button
        class="button is-info"
        @click="next"
        expanded
        :loading="isLoading"
      >
        Simpan & Lanjut
      </b-button>
    </b-field>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { dateFormat } from "@/plugins/timeformat.js";

export default {
  data() {
    const today = new Date();
    return {
      isLoading: false,
      checkbox: false,
      isCardModalActive: false,
      edited_index: -1,
      empty: ["Belum ada data"],
      educations: [],
      active_education: {
        id: null,
        scope: null,
        organizer: null,
        location: null,
        start_date: null,
        end_date: null,
        paid_by: null,
      },
      active_education_default: {
        id: null,
        scope: null,
        organizer: null,
        location: null,
        start_date: null,
        end_date: null,
        paid_by: null,
      },
      minDate: new Date(
        today.getFullYear() - 70,
        today.getMonth(),
        today.getDate()
      ),
      maxStartDate: new Date(),
      maxEndDate: new Date(
        today.getFullYear() +5,
        today.getMonth(),
        today.getDate(),
      ),
      minDateEnd: today
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    ...mapGetters({
      applicant: "applicant",
      stepper_position: "stepper_position",
    }),
    formTitle() {
      return this.edited_index === -1
        ? "Tambah Data Pendidikan Informal/Kursus"
        : "Ubah Data Pendidikan Informal/Kursus";
    },
  },
  methods: {
    ...mapActions({
      setApplicant: "setApplicant",
      setStepperPosition: "setStepperPosition",
    }),
    add() {
      this.isCardModalActive = true
      this.minDateEnd = new Date()
    },
    next() {
      if (this.educations.length === 0 && this.checkbox === false) {
        this.$buefy.snackbar.open({
          message:
            "Riwayat pendidikan informal masih kosong, silahkan mengisi terlebih dahulu",
          type: "is-info",
          position: "is-top",
          actionText: "Tutup",
          indefinite: false,
        });
      } else if (this.educations.length > 0 && this.checkbox === true) {
        this.$buefy.snackbar.open({
          message: "Silahkan memeriksa kembali riwayat pendidikan informal",
          type: "is-info",
          position: "is-top",
          actionText: "Tutup",
          indefinite: false,
        });
      } else {
        this.setStepperPosition(10);
      }
    },
    async refresh() {
      this.isLoading = true;
      await this.$axios
        .get("applicants/" + this.applicant.id + `/informal-educations`)
        .then((response) => {
          this.educations = response.data.data;
        })
        .catch((e) => {
          this.message = "Error: " + e.response.data.message;
          this.$buefy.snackbar.open({
            message: this.message,
            type: "is-info",
            position: "is-top",
            actionText: "Tutup",
            indefinite: false,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    saveStartDate(date) {
      this.active_education.start_date = dateFormat(date);
      this.minDateEnd = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
    },
    saveEndDate(date) {
      this.active_education.end_date = dateFormat(date);
    },
    closeModal() {
      this.isCardModalActive = false;
      this.active_education = Object.assign({}, this.active_education_default);
      this.edited_index = -1;
    },
    async saveEducation(e) {
      // delete leading spaces
    this.active_education.scope = this.active_education.scope ? this.active_education.scope.replace(/^\s*/, "") : null
    this.active_education.scope = this.active_education.scope != null ? this.active_education.scope.length == 1 && (this.active_education.scope == 0 || this.active_education.scope == "0") ? "" : this.active_education.scope : null
    this.active_education.scope = this.active_education.scope != null ? this.active_education.scope.length > 1 && this.active_education.scope.trim().length === 1 && (this.active_education.scope.charAt(0) == 0 || this.active_education.scope.charAt(0) == "0") ? "" : this.active_education.scope : null
    this.active_education.organizer = this.active_education.organizer ? this.active_education.organizer.replace(/^\s*/, "") : null
    this.active_education.organizer = this.active_education.organizer != null ? this.active_education.organizer.length == 1 && (this.active_education.organizer == 0 || this.active_education.organizer == "0") ? "" : this.active_education.organizer : null
    this.active_education.organizer = this.active_education.organizer != null ? this.active_education.organizer.length > 1 && this.active_education.organizer.trim().length === 1 && (this.active_education.organizer.charAt(0) == 0 || this.active_education.organizer.charAt(0) == "0") ? "" : this.active_education.organizer : null
    this.active_education.location = this.active_education.location ? this.active_education.location.replace(/^\s*/, "") : null
    this.active_education.paid_by = this.active_education.paid_by ? this.active_education.paid_by.replace(/^\s*/, "") : null
    this.active_education.paid_by = this.active_education.paid_by != null ? this.active_education.paid_by.length == 1 && (this.active_education.paid_by == 0 || this.active_education.paid_by == "0") ? "" : this.active_education.paid_by : null
    this.active_education.paid_by = this.active_education.paid_by != null ? this.active_education.paid_by.length > 1 && this.active_education.paid_by.trim().length === 1 && (this.active_education.paid_by.charAt(0) == 0 || this.active_education.paid_by.charAt(0) == "0") ? "" : this.active_education.paid_by : null

      if (
        this.$refs.form.checkValidity() &&
        this.active_education.temp_start_date != null &&
        this.active_education.temp_end_date != null
      ) {
        if (!(/^[A-Za-z0-9\s]+$/i.test(this.active_education.scope)) ||
          !(/^[A-Za-z0-9\s]+$/i.test(this.active_education.organizer))) {
            this.$buefy.snackbar.open({
							message: "Gagal! Bidang dan penyelenggara hanya bisa alfabet, angka, dan spasi",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
        }
        if (!(/^[A-Za-z\s]+$/i.test(this.active_education.location))) {
          this.$buefy.snackbar.open({
							message: "Gagal! Kota hanya bisa alfabet dan spasi",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
        }
        if (!(/^[A-Za-z0-9\s]+$/i.test(this.active_education.paid_by))) {
            this.$buefy.snackbar.open({
							message: "Gagal! Dibiayai oleh hanya bisa alfabet, angka, dan spasi",
							type: 'is-info',
							position: 'is-top',
							actionText: 'Tutup',
							indefinite: false,
						})
					return false;
        }
        if (this.active_education.start_date >= this.active_education.end_date) {
          this.$buefy.snackbar.open({
            message: "Gagal! Tanggal mulai harus lebih kecil dari tanggal selesai",
            type: 'is-info',
            position: 'is-top',
            actionText: 'Tutup',
            indefinite: false,
          })
					return false;
        }

        this.isLoading = true;
        this.message = null;
        if (this.edited_index > -1) {
          await this.$axios
            .patch(
              `applicants/` +
                this.applicant.id +
                `/informal-educations/` +
                this.active_education.id,
              this.active_education
            )
            .then((response) => {
              this.message = response.data.message;
              if (response.data.status) {
                Object.assign(
                  this.educations[this.edited_index],
                  this.active_education
                );
                this.refresh();
                this.closeModal();
              }
            })
            .catch((e) => {
              this.message = "Error: " + e.response.data.message;
            })
            .finally(() => {
              this.minDateEnd = new Date()
              this.isLoading = false;
              this.$buefy.snackbar.open({
                message: this.message,
                type: "is-info",
                position: "is-top",
                actionText: "Tutup",
                indefinite: false,
              });
            });
        } else {
          await this.$axios
            .post(
              `applicants/` + this.applicant.id + `/informal-educations`,
              this.active_education
            )
            .then((response) => {
              this.message = response.data.message;
              if (response.data.status) {
                this.active_education.id = response.data.data.id;
                this.educations.push(this.active_education);
                this.refresh();
                this.closeModal();
              }
            })
            .catch((e) => {
              this.message = "Error: " + e.response.data.message;
            })
            .finally(() => {
              this.minDateEnd = new Date()
              this.isLoading = false;
              this.$buefy.snackbar.open({
                message: this.message,
                type: "is-info",
                position: "is-top",
                actionText: "Tutup",
                indefinite: false,
              });
            });
        }
      } else {
        this.message = "Gagal! Data kurang/tidak valid, silahkan cek kembali";
        this.$buefy.snackbar.open({
          message: this.message,
          type: "is-info",
          position: "is-top",
          actionText: "Tutup",
          indefinite: false,
        });
      }
      e.preventDefault();
    },
    editEducation(education) {
      this.edited_index = this.educations.indexOf(education);
      this.active_education = Object.assign({}, education);
      this.active_education.temp_start_date =
        education.start_date == null ? null : new Date(education.start_date);
      this.active_education.temp_end_date =
        education.start_date == null ? null : new Date(education.end_date);
      this.isCardModalActive = true;
      this.minDateEnd = new Date(this.active_education.temp_start_date.getFullYear(), this.active_education.temp_start_date.getMonth(), this.active_education.temp_start_date.getDate() + 1)
    },
    prev() {
      this.setStepperPosition(8);
    },
    onlyAlphaNum($event) {
      let char = String.fromCharCode($event.keyCode);
      if (/^[A-Za-z0-9\s]+$/i.test(char)) {
        return true;
      } else {
        $event.preventDefault();
        return false;
      }
    },
    onlyAlphabet($event) {
      let char = String.fromCharCode($event.keyCode);
      if (/^[A-Za-z\s]+$/i.test(char)) { //check if alphabet
				return true;
			} else {
				$event.preventDefault();
				return false;
			}
    }
  },
};
</script>
