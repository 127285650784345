<template>
  <section>
		<b-notification
			class="mb-4 has-text-centered"
			type="is-warning"			
			role="alert"
			:closable="false"
		>
			PROSES PENERIMAAN KARYAWAN TIDAK DIPUNGUT BIAYA APAPUN <br>
			Jika terdapat penyimpangan, dapat melaporkan kepada kami melalui <br> 
			SMS 0813.8127.5555 atau email : kontakhrd@indomaret.co.id
		</b-notification>
	</section>
</template>

<script>
export default {
  name: "Alert"
};
</script>
